import PbText from '@nsf/cms/components/Pagebuilder/Components/Text.vue'
import PbHtml from '@nsf/cms/components/Pagebuilder/Components/Html.vue'
import PbButtons from '@nsf/cms/components/Pagebuilder/Components/Buttons.vue'
import PbColumns from '@nsf/cms/components/Pagebuilder/Components/Columns.vue'
import PbDivider from '@nsf/cms/components/Pagebuilder/Components/Divider.vue'
import PbTip from '@nsf/cms/components/Pagebuilder/Components/Tip.vue'
import PbRow from '@nsf/cms/components/Pagebuilder/Components/Row.vue'
import PbHeading from '@nsf/cms/components/Pagebuilder/Components/Heading.vue'
import PbImage from '@nsf/cms/components/Pagebuilder/Components/Image.vue'
import PbVideo from '@nsf/cms/components/Pagebuilder/Components/Video.vue'
import PbAccordion from '@nsf/cms/components/Pagebuilder/Components/Accordion.vue'
import PbTableOfContents from '@nsf/cms/components/Pagebuilder/Components/TableOfContents.vue'
import PbTabs from '@nsf/cms/components/Pagebuilder/Components/Tabs.vue'
import PbCustomMenu from '@nsf/cms/components/Pagebuilder/Components/CustomMenu.vue'
import PbProducts from '@nsf/cms/components/Pagebuilder/Components/Products.vue'
import PbBlock from '@nsf/cms/components/Pagebuilder/Components/Block.vue'
import PbArticles from '@nsf/cms/components/Pagebuilder/Components/Articles.vue'
import PbWidget from '@nsf/cms/components/Pagebuilder/Components/Widget.vue'
import PbSlider from '@nsf/cms/components/Pagebuilder/Components/Slider.vue'
import PbBanner from '@nsf/cms/components/Pagebuilder/Components/Banner.vue'
import PbPdf from '@nsf/cms/components/Pagebuilder/Components/Pdf.vue'
import PbBannerBlocks from '@nsf/base/pageBuilder/BannerBlocks.vue'
import { removeHtmlTags } from '@nsf/utils/StringUtils.js'

// eslint-disable-next-line complexity
export const getComponent = (type) => {
  switch (type) {
    case 'Buttons':
      return PbButtons
    case 'Columns':
      return PbColumns
    case 'Text':
      return PbText
    case 'Html':
      return PbHtml
    case 'Divider':
      return PbDivider
    case 'Image':
      return PbImage
    case 'Video':
      return PbVideo
    case 'Tip':
      return PbTip
    case 'Heading':
      return PbHeading
    case 'Row':
      return PbRow
    case 'Accordion':
      return PbAccordion
    case 'Table Of Contents':
      return PbTableOfContents
    case 'Tabs':
      return PbTabs
    case 'Custom Menu':
      return PbCustomMenu
    case 'Products':
      return PbProducts
    case 'Articles':
      return PbArticles
    case 'Block':
      return PbBlock
    case 'Widget':
      return PbWidget
    case 'Slider':
      return PbSlider
    case 'Banner':
      return PbBanner
    case 'Pdf':
      return PbPdf
    case 'BannerBlocks':
      return PbBannerBlocks
    default:
      return 'div'
  }
}

// eslint-disable-next-line complexity
export const convertSettingsToInlineStyles = (settings, excludedStyles = {}) => {
  const styles = []

  if (!settings) {
    return ''
  }

  if (settings.borderStyle && !excludedStyles.borderStyle) {
    styles.push(`border-style: ${settings.borderStyle}`)
  }

  if (settings.borderColor && !excludedStyles.borderColor) {
    styles.push(`border-color: ${settings.borderColor}`)
  }

  if (settings.borderWidth && !excludedStyles.borderWidth) {
    styles.push(`border-width: ${settings.borderWidth}`)
  }

  if (settings.borderRadius && !excludedStyles.borderRadius) {
    if (settings.borderRadius.topLeft) {
      styles.push(`border-top-left-radius: ${settings.borderRadius.topLeft}`)
    }
    if (settings.borderRadius.topRight) {
      styles.push(`border-top-right-radius: ${settings.borderRadius.topRight}`)
    }
    if (settings.borderRadius.bottomRight) {
      styles.push(`border-bottom-right-radius: ${settings.borderRadius.bottomRight}`)
    }
    if (settings.borderRadius.bottomLeft) {
      styles.push(`border-bottom-left-radius: ${settings.borderRadius.bottomLeft}`)
    }
  }

  if (settings.backgroundColor && !excludedStyles.backgroundColor) {
    styles.push(`background-color: ${settings.backgroundColor}`)
  }

  if (settings.backgroundImage && !excludedStyles.backgroundImage) {
    styles.push(`background-image: url( ${settings.backgroundImage})`)
  }

  if (settings.backgroundSize && !excludedStyles.backgroundSize) {
    styles.push(`background-size: ${settings.backgroundSize}`)
  }

  if (settings.backgroundPosition && !excludedStyles.backgroundPosition) {
    styles.push(`background-position: ${settings.backgroundPosition}`)
  }

  if (settings.backgroundRepeat && !excludedStyles.backgroundRepeat) {
    styles.push(`background-repeat: ${settings.backgroundRepeat}`)
  }

  if (settings.minHeight && !excludedStyles.minHeight) {
    styles.push(`min-height: ${settings.minHeight}`)
  }

  if (settings.height && !excludedStyles.height) {
    styles.push(`height: ${settings.height}`)
  }

  if (settings.maxWidth && !excludedStyles.maxWidth) {
    styles.push(`max-width: ${settings.maxWidth}`)
  }

  if (settings.width && !excludedStyles.width) {
    styles.push(`width: ${settings.width}`)
  }

  if (settings.aspectRatio && !excludedStyles.aspectRatio) {
    styles.push(`aspect-ratio: ${settings.aspectRatio}`)
  }

  if (settings.verticalAlignment && !excludedStyles.verticalAlignment) {
    getVerticalAlignmentInlineStyles(settings.verticalAlignment).forEach((style) => {
      styles.push(style)
    })
  }

  if (settings.horizontalAlignment && !excludedStyles.horizontalAlignment) {
    getHorizontalAlignmentInlineStyles(settings.horizontalAlignment).forEach((style) => {
      styles.push(style)
    })
  }

  if (settings.margin && !excludedStyles.margin) {
    if (settings.margin.left) {
      styles.push(`margin-left: ${settings.margin.left}`)
    }
    if (settings.margin.top) {
      styles.push(`margin-top: ${settings.margin.top}`)
    }
    if (settings.margin.right) {
      styles.push(`margin-right: ${settings.margin.right}`)
    }
    if (settings.margin.bottom) {
      styles.push(`margin-bottom: ${settings.margin.bottom}`)
    }
  }

  if (settings.padding && !excludedStyles.padding) {
    if (settings.padding.left) {
      styles.push(`padding-left: ${settings.padding.left}`)
    }
    if (settings.padding.top) {
      styles.push(`padding-top: ${settings.padding.top}`)
    }
    if (settings.padding.right) {
      styles.push(`padding-right: ${settings.padding.right}`)
    }
    if (settings.padding.bottom) {
      styles.push(`padding-bottom: ${settings.padding.bottom}`)
    }
  }

  return styles.join(';')
}

export const convertAlignmentSettingsToInlineStyles = (settings, excludedStyles = {}) => {
  const styles = []
  if (settings.verticalAlignment && !excludedStyles.verticalAlignment) {
    getVerticalAlignmentInlineStyles(settings.verticalAlignment).forEach((style) => {
      styles.push(style)
    })
  }

  if (settings.horizontalAlignment && !excludedStyles.horizontalAlignment) {
    getHorizontalAlignmentInlineStyles(settings.horizontalAlignment).forEach((style) => {
      styles.push(style)
    })
  }

  return styles.join(';')
}

function getVerticalAlignmentInlineStyles(alignment) {
  const styles = []
  styles.push(`display: flex`)
  styles.push(`flex-direction: column`)

  switch (alignment) {
    case 'center':
      styles.push(`justify-content: center`)
      break
    case 'bottom':
      styles.push(`justify-content: flex-end`)
      break
    case 'top':
    default:
      styles.push(`justify-content: flex-start`)
      break
  }

  return styles
}

function getHorizontalAlignmentInlineStyles(alignment) {
  const styles = []

  switch (alignment) {
    case 'center':
      styles.push(`text-align: center`)
      break
    case 'right':
      styles.push(`text-align: right`)
      break
    case 'left':
    default:
      styles.push(`text-align: left`)
      break
  }

  return styles
}

export function getTextContentFromPagebuilderItem(pagebuilderItem) {
  let output = ''

  if (typeof pagebuilderItem !== 'object') {
    return ''
  }

  switch (pagebuilderItem.type) {
    case 'Text':
      output = removeHtmlTags(pagebuilderItem.content)
      break
    case 'Tip':
      output = removeHtmlTags(pagebuilderItem.settings?.tipContent)
      break
    default:
      break
  }

  if (Array.isArray(pagebuilderItem.children)) {
    pagebuilderItem.children.forEach((child) => {
      const childText = getTextContentFromPagebuilderItem(child)
      output += childText ? `${childText} ` : ''
    })
  }

  return output
}

export const extractAnchorsFromHtml = (htmlString) => {
  const regex = /<h2\s*(.*?)>(.*?)<\/h2>/g
  let matches
  const h2Array = []

  while ((matches = regex.exec(htmlString)) !== null) {
    const attributesString = matches[1]
    const idMatch = attributesString.match(/id="([^"]*)"/)

    if (idMatch) {
      h2Array.push({
        text: removeHtmlTags(matches[2]),
        id: idMatch[1],
        tag: 'h2',
      })
    }
  }

  return h2Array
}

export function getAnchorsFromHeadings(pagebuilderItem) {
  let anchors = []

  if (typeof pagebuilderItem !== 'object') {
    return []
  }

  switch (pagebuilderItem.type) {
    case 'Text':
      // eslint-disable-next-line no-param-reassign
      anchors = extractAnchorsFromHtml(pagebuilderItem.content, pagebuilderItem.id)
      break
    case 'Heading':
      anchors.push({
        id: `pb-${pagebuilderItem.id}`,
        tag: pagebuilderItem.settings.tag || 'h2',
        text: pagebuilderItem.content,
      })
      break
    default:
      break
  }

  if (Array.isArray(pagebuilderItem.children)) {
    pagebuilderItem.children.forEach((child) => {
      const childAnchors = getAnchorsFromHeadings(child)
      anchors = [...anchors, ...childAnchors]
    })
  }

  return anchors
}

export const hasChildComponentType = (pagebuilderItem, componentType) => {
  if (typeof pagebuilderItem !== 'object') {
    return false
  }

  if (pagebuilderItem.type === componentType) {
    return true
  }

  if (Array.isArray(pagebuilderItem.children)) {
    for (const child of pagebuilderItem.children) {
      if (hasChildComponentType(child, componentType)) {
        return true
      }
    }
  }

  return false
}

export const movePagebuilderDataToComponents = (component, pagebuilderData) => {
  if (!component) {
    return
  }
  const sourceId = component.dataSourceId || component.data_source_id
  const map = {}

  for (const item of pagebuilderData) {
    const itemSourceId = item.dataSourceId || item.data_source_id
    map[itemSourceId] = item
  }

  if (sourceId && map[sourceId]) {
    // eslint-disable-next-line no-param-reassign
    component.pagebuilderData = map[sourceId]
  }

  if (component.type === 'Block') {
    const blockSourceId = component.settings.entityData.dataSourceId
    if (blockSourceId && map[blockSourceId]) {
      // eslint-disable-next-line no-param-reassign
      component.pagebuilderData = map[blockSourceId]

      if (component.pagebuilderData.items?.length) {
        if (component.pagebuilderData.items[0].attributes?.pagebuilderContent) {
          const pagebuilderObj = JSON.parse(component.pagebuilderData.items[0].attributes.pagebuilderContent)
          // eslint-disable-next-line no-param-reassign
          component.pagebuilderData.items[0].pagebuilderContent = pagebuilderObj
          movePagebuilderDataToComponents(pagebuilderObj, pagebuilderData)
        }
      }
    }
  }

  if (component.children?.length) {
    for (const child of component.children) {
      movePagebuilderDataToComponents(child, pagebuilderData)
    }
  }
}
