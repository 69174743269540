<template>
  <div
    v-if="anchors.length"
    class="table-of-content pagebuilder-toc"
  >
    <div class="table-of-content__head">
      <svg aria-hidden="true">
        <use xlink:href="/images/icons-base.svg#table-of-content" />
      </svg>

      <h2>{{ $t('global.articles.Article content') }}</h2>
    </div>

    <div :id="`pb-toc-${uid}`" ref="toc-content" class="table-of-content__content">
      <ul :class="{'toc-has-h1': hasH1, 'toc-has-multiple-levels': hasMultipleLevels}">
        <li v-for="(anchor, i) in anchors" :key="i" :class="`toc-${anchor.tag}`">
          <a :href="`#${anchor.id}`">{{ anchor.text }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagebuilderTableOfContents',

  inject: ['WindowDimensionProvider'],

  props: {
    item: {
      type: Object,
      required: true,
    },

    computedAnchors: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    uid() {
      return this._uid
    },

    querySelector() {
      const id = this.$refs?.['toc-content']?.id
      return `#${id} a[href^="#"]`
    },

    anchors() {
      if (this.computedAnchors?.length) {
        return this.computedAnchors
      }

      const { tocAnchors } = this.$store.state['_base/pagebuilder']

      if (tocAnchors) {
        return tocAnchors
      }

      return []
    },

    hasH1() {
      return !!this.anchors.find((item) => item.tag === 'h1')
    },

    hasMultipleLevels() {
      return new Set(this.anchors.map((item) => item.tag)).size > 1
    },
  },

  mounted() {
    document.querySelectorAll(this.querySelector).forEach((anchor) => {
      anchor.addEventListener('click', this.handleAnchorClick)
    })
  },

  beforeDestroy() {
    document.querySelectorAll(this.querySelector).forEach((anchor) => {
      anchor.removeEventListener('click', this.handleAnchorClick)
    })
  },

  methods: {
    handleAnchorClick(e) {
      e.preventDefault()

      const target = document.querySelector(e.target.getAttribute('href'))

      let offset = 20
      if (window.innerWidth < this.WindowDimensionProvider.size.sm) {
        offset += document.querySelector('#header').clientHeight
      }

      const bodyRect = document.body.getBoundingClientRect().top
      const targetRect = target.getBoundingClientRect().top
      const targetPosition = targetRect - bodyRect
      const offsetPosition = targetPosition - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    },
  },

}
</script>

<style lang="scss">
.table-of-content .highlight {
  a {
    font-weight: 650;
  }

  &::before {
    top: 14px;
    left: -1px;
    width: 8px;
    height: 8px;
    border: 2px solid #02284e;
    background: none;
  }
}
</style>
