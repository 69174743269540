var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"dropdown",staticClass:"dropdown-container"},[(_vm.label || _vm.optional || _vm.required)?_c('div',{staticClass:"dropdown-title"},[(_vm.label)?_c('v-label',[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.optional)?_c('Optional',{attrs:{"optional":_vm.optional}}):_vm._e(),_vm._v(" "),(_vm.required)?_c('Required'):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"error-wrapper",class:{ error: _vm.error && _vm.errorText }},[_c('button',{staticClass:"dropdown-select",class:[
        { 'is-open': _vm.isOpen },
        { disabled: _vm.disabled },
        { error: _vm.error },
      ],on:{"click":_vm.toggleDropdown}},[_c('span',{staticClass:"search-text",class:{ 'option-selected': _vm.hasValueSelected }},[_vm._v(_vm._s(_vm.dropdownText))]),_vm._v(" "),_c('svg',{class:{ 'is-open': _vm.isOpen },attrs:{"width":"24","height":"24"}},[_c('use',{attrs:{"xlink:href":"/images/icons-base.svg#arrow-down"}})])]),_vm._v(" "),(_vm.error && _vm.errorText)?_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.errorText))]):_vm._e()]),_vm._v(" "),(_vm.isOpen)?_c('div',{staticClass:"dropdown-box",class:{
      'dropdown-box--without-label': !_vm.label && !_vm.optional && !_vm.required,
    }},[_c('div',{staticClass:"dropdown-options"},[(_vm.searchFilterEnabled)?_c('v-input',{staticClass:"search-filter",attrs:{"type":"text","placeholder":_vm.$t('base.header.Search'),"tabindex":"0"},scopedSlots:_vm._u([{key:"leadingIcon",fn:function(){return [_c('svg',[_c('use',{attrs:{"xlink:href":"/images/icons-base.svg#search"}})])]},proxy:true}],null,false,1852697211),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"loading"},[_c('span',{staticClass:"spinner-border"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t("base.Loading"))+"...")])]):(_vm.filteredOptions.length > 0)?_c('div',{ref:"dropdownOptions",staticClass:"dropdown-results",class:{ 'dropdown-results-scrollbar': _vm.filteredOptionsCount > 5 }},_vm._l((_vm.filteredOptions),function(option,index){return _c(_vm.optionComponent,{key:index,tag:"component",attrs:{"option":option,"tabindex":"0"},on:{"select":_vm.onSelect}})}),1):_c('span',{staticClass:"no-results"},[_vm._v(_vm._s(_vm.$t("algolia.No results found")))])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }