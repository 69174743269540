import { $PlacementView } from '../providers/PlacementViewProvider.js'

export default {
  data() {
    return {
      observer: null,
      hasBeenSeen: false,
    }
  },

  inject: {
    [$PlacementView]: { default: null },
  },

  mounted() {
    if (!this.$PlacementView) {
      return
    }
    this.observer = new IntersectionObserver(this.handleIntersection, {
      threshold: 0.4,
    })

    this.observer.observe(this.$el)
  },

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },

  methods: {
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !this.hasBeenSeen) {
          this.hasBeenSeen = true

          this.$PlacementView?.observeObject(this.getPlacementObjectData(), this.getPlacementObjectType())

          this.observer.disconnect()
        }
      })
    },

    // implement this method in the component to return the desired data for the placementView event
    getPlacementObjectData() {
      return null
    },

    getPlacementObjectType() {
      return 'nocontent'
    },
  },
}
