import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
export default {
  name: 'PagebuilderTip',
  functional: true,
  props: {
    item: {
      type: Object,
      default: function _default() {}
    }
  },
  render: function render(h, ctx) {
    var tip = ctx.props.item || {};
    var tipSettings = tip.settings || {};
    var iconSetSrc = "/cms-cdn/media/gl/files/pagebuilder/icons.svg";
    if (!tipSettings.visible) {
      return [];
    }
    var blogCategoryTipIcon = ctx.parent.$store.state['_base/pagebuilder'].blogCategoryTipIcon;
    var useBlogCategoryIcon = !tipSettings.icon && blogCategoryTipIcon;
    var img = null;
    var svg = null;
    if (useBlogCategoryIcon) {
      img = h('img', {
        class: 'tip-icon',
        alt: 'tip-icon',
        src: "".concat(ctx.parent.$config.public.magentoUrl, "/").concat(blogCategoryTipIcon)
      });
    } else {
      svg = h('svg', {}, [h('use', {
        attrs: {
          'xlink:href': "".concat(iconSetSrc, "#").concat(tipSettings.icon || 'tip')
        }
      })]);
    }
    var tipLeft = h('div', {
      class: 'tip-left'
    }, [img || svg]);
    var tipRight = h('div', {
      class: 'tip-right'
    }, [h('div', {
      class: 'tip-title'
    }, tipSettings.title), h('div', {
      class: 'tip-content'
    }, tipSettings.content)]);
    var classes = ['pagebuilder-tip'];
    if (tipSettings !== null && tipSettings !== void 0 && tipSettings.cssClass) {
      classes.push(tipSettings.cssClass);
    }
    return h('div', {
      class: classes.join(' ')
    }, [tipLeft, tipRight]);
  }
};