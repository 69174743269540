import logger from '@nsf/my-account/logger.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import myAccountServiceClient from '../clients/MyAccountServiceClient.js'

const {
  public: { recaptchaEnabled },
} = useRuntimeConfig()

/* eslint-disable import/prefer-default-export */
export const checkEmail = async (email, ctx) => {
  let captchaQuery = ''
  if (ctx?.$recaptcha && recaptchaEnabled) {
    captchaQuery = `?captcha=${await ctx.$recaptcha.execute('submit')}`
  }

  try {
    const path = `/clients/check/email/${email}${captchaQuery}`

    const { data, status } = await myAccountServiceClient.get(path)

    return {
      _exists: !!data.clientId,
      status,
      clientId: data.clientId,
    }
  } catch (e) {
    logger.error('checkEmail(%s) failed: %s', email, e.message)

    return {
      _exists: true,
      status: 500,
      clientId: null,
    }
  }
}

export const setEmail = async (clientId, email) => {
  try {
    const { status, ok } = await myAccountServiceClient.put(`/clients/${clientId}`, { email })

    return {
      ok,
      status,
    }
  } catch (e) {
    logger.error(
      'setRegistrationEmail(%s) failed: %s',
      clientId,
      email,
      e.message,
    )

    return {
      ok: false,
      status: 500,
    }
  }
}

export const resendRegistrationEmail = async (clientId) => {
  try {
    const { status, ok } = await myAccountServiceClient.post(
      `/clients/${clientId}/verify-email`,
    )

    if (!ok) {
      throw new Error('Response not ok')
    }

    return {
      ok: true,
      status,
    }
  } catch (e) {
    logger.error('resendRegistrationEmail(%s) failed: %s', clientId, e.message)

    return {
      ok: false,
      status: 500,
    }
  }
}
