function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { getComponent, movePagebuilderDataToComponents } from '@nsf/utils/PagebuilderUtils.js';
import logger from '@nsf/cms/logger.js';
import ShowMore from './ShowMore.vue';
export default {
  name: 'PagebuilderRender',
  functional: true,
  props: {
    pagebuilderObject: {
      type: Object,
      default: function _default() {}
    },
    pagebuilderString: {
      type: String,
      default: ''
    },
    noContainer: {
      type: Boolean,
      default: false
    },
    pagebuilderData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  render: function render(h, ctx) {
    var _ctx$props$pagebuilde, _obj;
    var items = [];
    var showMoreItems = [];
    var obj = null;
    if (ctx.props.pagebuilderString) {
      try {
        obj = JSON.parse(ctx.props.pagebuilderString);
      } catch (e) {
        logger.error('pagebuilderRender pagebuilderString parse failed', e.message);
      }
    } else {
      obj = ctx.props.pagebuilderObject;
    }
    if ((_ctx$props$pagebuilde = ctx.props.pagebuilderData) !== null && _ctx$props$pagebuilde !== void 0 && _ctx$props$pagebuilde.length) {
      movePagebuilderDataToComponents(obj, ctx.props.pagebuilderData);
    }
    var children = ((_obj = obj) === null || _obj === void 0 ? void 0 : _obj.children) || [];
    var pushToShowMoreItems = false;
    var _iterator = _createForOfIteratorHelper(children),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _child$settings;
        var child = _step.value;
        if (!(child !== null && child !== void 0 && (_child$settings = child.settings) !== null && _child$settings !== void 0 && _child$settings.visible)) {
          continue;
        }
        if (child.type === 'Break Content') {
          pushToShowMoreItems = true;
          continue;
        }
        var childComponent = h(getComponent(child.type), {
          props: {
            item: child,
            noContainer: ctx.props.noContainer
          }
        });
        var addToArray = pushToShowMoreItems ? showMoreItems : items;
        if (child.type === 'Row') {
          addToArray.push(childComponent);
        } else {
          addToArray.push(h('div', {
            class: ctx.props.noContainer ? '' : 'container'
          }, [childComponent]));
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    var classes = ['pb-render'];
    if (ctx.data.staticClass) {
      classes.push(ctx.data.staticClass);
    }
    if (ctx.props.noContainer) {
      classes.push('no-container');
    }
    if (showMoreItems.length) {
      return h('div', {
        class: classes.join(' ')
      }, [h('div', {}, items), h(ShowMore, {
        props: {
          items: showMoreItems
        }
      })]);
    }
    return h('div', {
      class: classes.join(' ')
    }, items);
  }
};