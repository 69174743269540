import { pbPublitasWidget, pbCountdownWidget, pbDrugLeafletWidget, pbPregnancyCalculatorWidget, pbAdvisoryWidget, pbPregnancyWeeks, pbCategoriesWidget, pbRecentArticlesWidget, pbSubCategoryBoxesWidget, pbCookiebotWidget, pbAtDoctorLinkWidget, pbSkinAnalyzerWidget, pbStreamsWidget, pbBmiCalculatorWidget, pbCalorieCalculatorWidget, pbPopularBrandsWidget, pbLoginButtonWidget, pbNewReturnFormWidget, pbPlacementBannerWidget, pbCustomWidget } from '@ioc/pageBuilder.js';
var getWidgetComponentByType = function getWidgetComponentByType(widgetType) {
  switch (widgetType) {
    case 'publitas_widget':
      return pbPublitasWidget;
    case 'countdown_widget':
      return pbCountdownWidget;
    case 'drug_leaflet_widget':
      return pbDrugLeafletWidget;
    case 'pregnancy_calculator_widget':
      return pbPregnancyCalculatorWidget;
    case 'advisory_widget':
      return pbAdvisoryWidget;
    case 'pregnancy_weeks_widget':
      return pbPregnancyWeeks;
    case 'advisory_categories_widget':
      return pbCategoriesWidget;
    case 'recent_articles_widget':
      return pbRecentArticlesWidget;
    case 'subcategory_boxes_widget':
      return pbSubCategoryBoxesWidget;
    case 'cookiebot_widget':
      return pbCookiebotWidget;
    case 'at_doctor_link_widget':
      return pbAtDoctorLinkWidget;
    case 'skin_analyzer_widget':
      return pbSkinAnalyzerWidget;
    case 'streams_widget':
      return pbStreamsWidget;
    case 'bmi_calculator_widget':
      return pbBmiCalculatorWidget;
    case 'calorie_calculator_widget':
      return pbCalorieCalculatorWidget;
    case 'popular_brands_widget':
      return pbPopularBrandsWidget;
    case 'login_button_widget':
      return pbLoginButtonWidget;
    case 'new_return_form_widget':
      return pbNewReturnFormWidget;
    case 'placement_banner_widget':
    case 'placement_api_banner_widget':
      return pbPlacementBannerWidget;
    case 'custom_widget':
      return pbCustomWidget;
    default:
      return 'div';
  }
};
export default {
  name: 'PbWidget',
  functional: true,
  props: {
    item: {
      type: Object,
      default: function _default() {}
    }
  },
  render: function render(h, ctx) {
    var _ctx$props, _ctx$props$item;
    var settings = ((_ctx$props = ctx.props) === null || _ctx$props === void 0 ? void 0 : (_ctx$props$item = _ctx$props.item) === null || _ctx$props$item === void 0 ? void 0 : _ctx$props$item.settings) || {};
    var widgetType = settings.type;
    if (!widgetType || !(settings !== null && settings !== void 0 && settings.visible)) {
      return [];
    }
    var widget = getWidgetComponentByType(widgetType);
    var props = {
      props: {
        item: ctx.props.item
      }
    };
    return h('div', {
      class: "pb-widget pb-widget-".concat(widgetType)
    }, [h(widget, props)]);
  }
};