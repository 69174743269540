/* eslint-disable sort-keys */
export default {
  features: {
    suklWidgetEnabled: false,

    // Cart
    salesRulesEnabled: true,
    fillCartFromUrlParamsEnabled: true,
    cartItemsMaxQtyNotificationEnabled: true,
    cartItemsStockEnabled: true,
    cartItemsAllowBackOrdersEnabled: true,
    cartItemsRowOriginalPriceEnabled: true,
    salesRuleRemovalEnabled: false,
    totalExcludingVatEnabled: false,
    couponsEnabled: true,
    competitionService: {
      competitionImagePath: '',
      competitionWidgetEnabled: false,
    },
    giftCardsEnabled: true,
    freeShippingBoxEnabled: true,
    freeShippingDescriptionEnabled: false,
    freeShippingViaSalesRulesEnabled: true,
    boundProductsEnabled: true,
    pickupIdFetchingEnabled: true,
    setCartIdFromUrlParamsEnabled: true,
    marketplaceGiftCardsDisabledForPure3p: true,

    // Shipping and payment
    orderTypeEnabled: true,
    otcOrderPreferenceEnabled: false,
    reservationsWithRxProductsEnabled: false,
    deliveryFeEnabled: true,
    paymentFeeEnabled: true,
    vouchersModalEnabled: false,
    removeCartItemsModalEnabled: true,
    reservationInfoBoxEnabled: false,
    reservationsIgnoreStockStatusEnabled: false,
    reservationCheckOpeningHoursEnabled: false,
    pickupPlaceCoordinatesEnabled: true,
    pickupPlaceAddressEnabled: true,
    pickupPlaceOpeningHoursEnabled: true,
    pickupPlaceExtensionAttributesEnabled: true,
    allowSelectFullBox: false,
    checkProductsAvailabilityInPharmacyEnabled: false,
    rxOnlyInReservationsEnabled: false,
    useCommonMap: false, // don't merge true to master, for dev only
    useDtcForBoxes: true,
    usePickupSelectV2: false,
    isDrmaxBoxUpdatedFromPickupPlaces: false,
    simpleDeliveryTimeEnabled: true,
    orderTypeOnBoxDetailEnabled: true,
    withPickupTimes: true,

    // Address forms
    fillFormsFromLogin: true,
    persistFormsInLocalStorage: true,
    phoneSlicingEnabled: true,
    alwaysDefaultPhoneCodeEnabled: true,
    streetNumberToMagentoEnabled: true,
    shippingAddressNameEnabled: true,
    countrySelectEnabled: false,
    countrySelectChoiceEnabled: false,
    companyDataPrefillEnabled: false,
    privateInvoiceEnabled: false,
    crnAndVatIdValidationOnlyForDomesticOrdersEnabled: false,
    billingAddressSubheadingEnabled: false,
    billingCompanyDetailInfoEnabled: true,
    optionalBillingInfoForCACOrderEnabled: false,
    optionalBillingInfoForCACReservationEnabled: false,
    customerNotesEnabled: false,
    removeSpacesFromZipForMagentoEnabled: true,
    disableB2BForMarketplace: true,

    // Address validation
    addressValidationEnabled: true,

    // Agreements
    cartAgreementsEnabled: true,
    emailSmsAgreementEnabled: true,
    heurekaAgreementEnabled: true,
    personalDataAgreementEnabled: false,
    priceComparisonAgreementEnabled: false,
    staffinoAgreementEnabled: false,
    agreeToPayNoteEnabled: true,

    // Placing orders
    drmaxGatewayMethodEnabled: true,
    gtmOrderInfoGqlEnabled: true,
    placeOrderDeliveryCalculationEnabled: true,
    drmaxCjEventEnabled: true,
    extraDataInPlaceOrderEnabled: true,

    // Thank you pages
    payUSuccessCheckingEnabled: true,
    extraOfferEnabled: true,
    dataLayerEventForCeneoEnabled: false,
    nsfSlotEnabled: false,
    bankTransferDetailsDisplayEnabled: false,
  },
}
