<template>
  <div :class="className" :style="inlineStyle">
    <v-category-box
      v-if="isBigBlocks || isBigBlocksWithText"
      :categories="categoriesLimited"
      :show-name="isBigBlocksWithText"
      :use-h2="false"
    />
    <v-categories
      v-else
      :categories="categoriesLimited"
      class="mb-2"
    />
  </div>
</template>
<script>

import { getCategoriesByIds } from '@nsf/catalog/repositories/CategoryRepository.js'
import CategoryBox from '@nsf/catalog/components/category/Categories/CategoryBox.vue'
import { convertSettingsToInlineStyles } from '@nsf/utils/PagebuilderUtils.js'

export default {
  name: 'PbCustomMenu',

  components: {
    'v-categories': () => import('@nsf/catalog/components/category/Categories/index.vue'),
    'v-category-box': CategoryBox,
  },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      categories: [],
    }
  },

  async fetch() {
    const fetchIds = []

    this.item.settings?.customMenuCategories?.forEach((cat) => {
      const category = this.mapDataFromConfig(cat)
      if (category.id && (!category.name || !category.image || !category.urlPath)) {
        fetchIds.push(category.id)
      }
      this.categories.push(category)
    })

    this.categories.sort((cat1, cat2) => cat2.priority - cat1.priority)

    if (fetchIds.length) {
      const { categories } = await getCategoriesByIds(fetchIds)

      const fetchedCategories = {}
      categories.forEach((cat) => {
        fetchedCategories[cat.id] = cat
      })

      for (const category of this.categories) {
        if (!fetchedCategories[category.id]) {
          // eslint-disable-next-line no-continue
          continue
        }

        if (!category.name) {
          category.name = fetchedCategories[category.id].name
        }
        if (!category.urlPath) {
          category.urlPath = `/${fetchedCategories[category.id].urlPath}`
        }
        if (!category.image && fetchedCategories[category.id].image) {
          category.image = `/media/catalog/category/${fetchedCategories[category.id].image}`
        }
      }
    }
  },

  computed: {
    categoriesLimited() {
      return this.categories.slice(0, this.categoriesLimit)
    },

    className() {
      return `${this.item.settings?.cssClass ? `${this.item.settings.cssClass} ` : ''}pagebuilder-custom-menu ${this.item.settings?.appearance}`
    },

    appearance() {
      return this.item.settings?.appearance || 'small_blocks'
    },

    categoriesLimit() {
      return this.item.settings?.customMenuLimit || 5
    },

    isBigBlocks() {
      return this.appearance === 'big_blocks'
    },

    isBigBlocksWithText() {
      return this.appearance === 'big_blocks_with_text'
    },

    isSmallBlocks() {
      return this.appearance === 'small_blocks'
    },

    inlineStyle() {
      return convertSettingsToInlineStyles(this.item?.settings || {})
    },
  },

  methods: {
    mapDataFromConfig({
      categoryId: id = 0, customImage: image = null, customLink: urlPath = '', customText: name = '', priority = 0,
    }) {
      return {
        id: Number(id),
        name,
        urlPath,
        image: image?.src || null,
        priority: Number(priority),
      }
    },
  },
}
</script>

<style scoped lang="css">
/* stylelint-disable */
.big_blocks .commercial-image {
  border-radius: 7px;
}
/* stylelint-enable */
</style>
