<template>
  <div
    v-if="isVisible"
    :class="`pb-block ${cssClass}`"
    :style="inlineStyle"
  >
    <PagebuilderRender
      v-if="blockPagebuilderObject"
      :pagebuilder-object="blockPagebuilderObject"
      no-container
      class="mb-2 mt-2"
    />
  </div>
</template>

<script>

import { convertSettingsToInlineStyles } from '@nsf/utils/PagebuilderUtils.js'
import { uuid } from '@nsf/utils/StringUtils.js'
import dayjs from 'dayjs'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { mapDataToModel } from '@nsf/core/Mapper.js'

const { cms: { cmsService } } = useAppConfig()
const { public: { cmsServiceHost } } = useRuntimeConfig()

export default {
  name: 'PagebuilderBlock',

  components: {
    /* webpackChunkName: "PagebuilderRender" */
    PagebuilderRender: () => import('@nsf/cms/components/Pagebuilder/PagebuilderRender.vue'),
  },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      blockData: null,
    }
  },

  computed: {
    blockPagebuilderObject() {
      if (!this.blockData) {
        return null
      }

      const blockWidget = this.blockWidgetData
      if (blockWidget) {
        if (blockWidget.settings.type === 'custom_widget') {
          blockWidget.pagebuilderObject = this.blockData?.pagebuilderContent ?? null
        }

        return {
          children: [blockWidget],
        }
      }

      return this.blockData?.pagebuilderContent ?? null
    },

    blockWidgetData() {
      const widgetBase = {
        type: 'Widget',
        id: uuid(),
        settings: {
          visible: true,
          type: '',
        },
      }

      const block = this.item.pagebuilderData?.items?.[0]
      if (block) {
        const identifier = block.attributes?.identifier
        if (identifier) {
          const widget = this.getBlockWidgetByIdentifier(identifier, widgetBase)

          if (widget.settings.type) {
            return widget
          }
        }
      }

      if (this.cssClass) {
        const widget = this.getBlockWidgetByCssClass(this.cssClass, widgetBase)

        if (widget.settings.type) {
          return widget
        }
      }

      return null
    },

    inlineStyle() {
      return convertSettingsToInlineStyles(this.settings)
    },

    cssClass() {
      return this.settings?.cssClass || ''
    },

    isVisible() {
      return this.settings?.visible || false
    },

    settings() {
      return this.item.settings || {}
    },
  },

  created() {
    if (!this.item.pagebuilderData) {
      this.loadBlockFromCmsService()
      return
    }

    this.blockData = this.item.pagebuilderData?.items?.[0]
  },

  methods: {
    async loadBlockFromCmsService() {
      const blockId = this.item.settings?.entityData?.entityIds?.[0]

      const response = await fetch(
        `${cmsServiceHost}/content/block/search`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Tenant': cmsService.tenant,
            'X-Store': cmsService.storeId,
          },
          body: JSON.stringify({
            filters: {
              // eslint-disable-next-line camelcase
              entity_id: {
                value: blockId,
                operator: 'is',
              },
            },
          }),
        },
      )

      if (response.status !== 200) {
        return
      }

      const responseObj = await response.json()

      if (responseObj && responseObj.items?.length) {
        const data = mapDataToModel(responseObj.items[0])
        if (data.attributes?.pagebuilderContent) {
          data.pagebuilderContent = JSON.parse(data.attributes.pagebuilderContent)
        }
        this.blockData = data
      }
    },

    getBlockWidgetByIdentifier(identifier, widgetBase) {
      const widget = { ...widgetBase }

      if (identifier === 'pregnancy-calculator-widget') {
        widget.settings.type = 'pregnancy_calculator_widget'
      } else if (identifier === 'pregnancy-weeks') {
        widget.settings.type = 'pregnancy_weeks_widget'
      } else if (identifier === 'countdown-widget') {
        widget.settings.type = 'countdown_widget'
        widget.settings.startDate = null
        widget.settings.endDate = null
        widget.settings.darkMode = false
      }

      return widget
    },

    getBlockWidgetByCssClass(cssClass, widgetBase) {
      const widget = { ...widgetBase }

      const widgetMap = {
        'pregnancy-calculator-widget': 'pregnancy_calculator_widget',
        'drug-leaflet-widget': 'drug_leaflet_widget',
        'advisory-widget': 'advisory_widget',
        'categories-widget': 'advisory_categories_widget',
        'cookiebot': 'cookiebot_widget',
        'custom-widget': 'custom_widget',
        'recent-articles-widget': 'recent_articles_widget',
        'at-doctor-link-widget': 'at_doctor_link_widget',
        'new-return-form-widget': 'new_return_form_widget',
      }

      if (cssClass && widgetMap[cssClass]) {
        widget.settings.type = widgetMap[cssClass]
      }

      if (cssClass?.startsWith('subCategoryBoxes-widget')) {
        const parts = cssClass.split('-')
        widget.settings.type = 'subcategory_boxes_widget'
        widget.settings.count = parts.length > 2 ? Number.parseInt(parts[2]) : null
      }

      if (cssClass?.startsWith('countdown-')) {
        const parts = cssClass.split('-')
        const format = {
          in: 'YYYYMMDDHHmm',
          out: 'YYYY-MM-DDTHH:mm',
        }

        widget.settings.type = 'countdown_widget'
        widget.settings.startDatetime = parts.length >= 2 ? dayjs(parts[1], format.in).format(format.out) : null
        widget.settings.endDatetime = parts.length >= 3 ? dayjs(parts[2], format.in).format(format.out) : null
        widget.settings.darkMode = parts.length >= 4 && parts[3] === 'dark'
      }

      if (cssClass?.startsWith('streamswidget-')) {
        const parts = cssClass.split('-')
        widget.settings.type = 'streams_widget'
        widget.settings.channelId = parts.length > 1 ? parts[1] : null
      }

      return widget
    },
  },
}
</script>
