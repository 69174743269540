import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.link.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import LazyImage from '@nsf/base/shared/LazyImage.vue';
import { mdMax } from '@nsf/ui-library/assets/styles/responsive.scss';
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js';
import { convertSettingsToInlineStyles } from '@nsf/utils/PagebuilderUtils.js';
var _useRuntimeConfig = useRuntimeConfig(),
  imageFormats = _useRuntimeConfig.public.imageFormats;
var generateImageOpts = function generateImageOpts(imageDesktop, imageMobile, isGif) {
  var desktopWidth = imageDesktop.width ? parseInt(imageDesktop.width, 10) : undefined;
  var desktopHeight = imageDesktop.height ? parseInt(imageDesktop.height, 10) : undefined;
  var mobileWidth = imageMobile.width ? parseInt(imageMobile.width, 10) : undefined;
  var mobileHeight = imageMobile.height ? parseInt(imageMobile.height, 10) : undefined;
  var opts = [_objectSpread(_objectSpread({
    path: imageDesktop.src,
    w: desktopWidth,
    h: desktopHeight
  }, (imageMobile === null || imageMobile === void 0 ? void 0 : imageMobile.src) && {
    media: "(min-width: 768.1px)"
  }), isGif && {
    format: 'gif'
  })];
  if (imageMobile !== null && imageMobile !== void 0 && imageMobile.src) {
    opts.unshift(_objectSpread({
      path: imageMobile.src,
      w: mobileWidth,
      h: mobileHeight,
      media: "(max-width: ".concat(mdMax, "px)")
    }, isGif && {
      format: 'gif'
    }));
  }
  return opts;
};
export default {
  name: 'PagebuilderImage',
  functional: true,
  props: {
    item: {
      type: Object,
      default: function _default() {}
    }
  },
  render: function render(h, ctx) {
    var _imageSettings$altern, _imageSettings$altern2;
    var image = ctx.props.item;
    var imageSettings = image.settings || {};
    var imageDesktop = imageSettings.imageDesktop || {};
    var imageMobile = imageSettings.imageMobile || {};
    if (!imageSettings.visible || !imageDesktop.src) {
      return [];
    }
    var caption = imageSettings.caption || null;
    var componentTag = imageSettings.link ? 'v-link' : 'div';
    var isGif = /(?:\.([^.]+))?$/.exec(imageDesktop.src)[1] === 'gif';
    var elementId = image.id;
    var classNames = ['pagebuilder-image'];
    if (imageSettings.cssClass) {
      classNames.push(imageSettings.cssClass);
    }

    /*
    Defining base width size {w} for possibility to get several differently sized images,
    browser will choose the right one causing reduction of downloaded data
    e.g. For device-pixel-ration=2 and viewport < 400, it will download the 800w image from srcset
    */
    var opts = generateImageOpts(imageDesktop, imageMobile, isGif);
    var imageStyle = convertSettingsToInlineStyles(imageSettings, {
      verticalAlignment: true,
      horizontalAlignment: true
    });
    var lazyImageData = {
      props: {
        alt: (_imageSettings$altern = imageSettings.alternativeText) !== null && _imageSettings$altern !== void 0 ? _imageSettings$altern : '',
        formats: [imageFormats].concat(_toConsumableArray(isGif ? ['gif'] : ['jpeg'])).join(','),
        height: imageDesktop.height ? parseInt(imageDesktop.height, 10) : null,
        imageStyle: imageStyle,
        isNotLazy: !imageSettings.isLazy,
        multiple: [1, 2, 4, 6],
        opts: opts,
        title: (_imageSettings$altern2 = imageSettings.alternativeText) !== null && _imageSettings$altern2 !== void 0 ? _imageSettings$altern2 : '',
        width: imageDesktop.width ? parseInt(imageDesktop.width, 10) : null
      }
    };
    var lazyImageElement = h(LazyImage, lazyImageData, []);
    var figureElement = h('figure', {
      attrs: {
        id: elementId
      }
    }, [lazyImageElement]);
    var innerElementChildren = [figureElement];
    if (caption) {
      var figcaptionElement = h('figcaption', {
        class: 'mb-2 caption-size',
        domProps: {
          innerHTML: caption
        }
      }, []);
      innerElementChildren.push(figcaptionElement);
    }
    var innerElement = h(componentTag, {
      attrs: {
        to: imageSettings.link,
        target: imageSettings.newTab ? '_blank' : undefined
      }
    }, innerElementChildren);
    var outerElementChildren = [innerElement];
    var outerStyle = convertSettingsToInlineStyles({
      verticalAlignment: (imageSettings === null || imageSettings === void 0 ? void 0 : imageSettings.verticalAlignment) || ''
    });
    switch (imageSettings === null || imageSettings === void 0 ? void 0 : imageSettings.horizontalAlignment) {
      case 'center':
        classNames.push('d-flex justify-content-center');
        break;
      case 'right':
        classNames.push('d-flex justify-content-end');
        break;
      default:
        classNames.push('d-flex justify-content-start');
        break;
    }

    // eslint-disable-next-line consistent-return
    return h('div', {
      class: classNames.join(' '),
      style: outerStyle
    }, [outerElementChildren]);
  }
};