<template functional>
  <div
    v-if="$options.isVisible(props.item)"
    :class="$options.cssClass(props.item)"
    :style="$options.outerInlineStyle(props.item)"
  >
    <iframe
      :style="$options.inlineStyle(props.item)"
      :src="$options.videoUrl(props.item)"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    />
  </div>
</template>

<script>

import {
  convertAlignmentSettingsToInlineStyles,
  convertSettingsToInlineStyles,
} from '@nsf/utils/PagebuilderUtils.js'

export default {
  name: 'PagebuilderVideo',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  outerInlineStyle(item) {
    return convertAlignmentSettingsToInlineStyles(item?.settings || {})
  },

  inlineStyle(item) {
    let settings = {}
    if (item?.settings) {
      settings = {
        ...item.settings,
        width: '100%',
        aspectRatio: '16/9',
      }
    }

    return convertSettingsToInlineStyles(settings, {
      horizontalAlignment: true,
      verticalAlignment: true,
    })
  },

  isVisible(item) {
    return item?.settings?.visible
  },

  videoUrl(item) {
    return item?.settings?.videoUrl
  },

  cssClass(item) {
    const classes = ['pagebuilder-video']

    if (item?.settings?.cssClass) {
      classes.push(item.settings.cssClass)
    }

    return classes.join(' ')
  },
}
</script>
