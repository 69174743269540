import logger from '@nsf/catalog/logger.js'
import reviewServiceClient from '@nsf/catalog/clients/ReviewServiceClient.js'
import { mapDataToReview } from '@nsf/catalog/mappers/ReviewMapper.js'

export const getReviews = async ({
  sku, limit, offset, filter, sort,
}) => {
  try {
    const { data, ok } = await reviewServiceClient.post(
      '/reviews',
      {
        sku, limit, offset, filter, sort,
      },
    )

    if (!ok) {
      throw new Error(data || 'Failed to get reviews')
    }

    return {
      reviews: mapDataToReview(data).map((review, index) => ({
        ...review,
        id: index,
        createdAt: review.date,
        detail: review.review,
        nickname: review.customerName,
      })),
      ok,
    }
  } catch (e) {
    logger.error(
      'getReviews failed: %s',
      e.message,
    )

    return {
      reviews: [],
      ok: false,
    }
  }
}

export const getRatingsByProductIds = async (skus) => {
  try {
    const payload = {
      skus: skus.map((sku) => String(sku)),
    }
    const response = await reviewServiceClient.post('/reviews/rating', JSON.stringify(payload))

    if (!response.data) {
      return {
        ok: false,
        reviews: [],
      }
    }

    if (response?.data?.code && [404, 500, 403].includes(response?.data?.code)) {
      return {
        ok: false,
        reviews: [],
      }
    }

    return {
      ok: true,
      reviews: response.data || [],
    }
  } catch (e) {
    logger.error('getReviewsByProductIds(%o) failed: %s', skus, e.message)

    return {
      ok: false,
      reviews: [],
    }
  }
}

export const getFilteredReviews = async (clientUid = null, sku = null, limit = 5, offset = 0, rating = null) => {
  try {
    const payload = {
      sku: String(sku) || null,
      limit,
      offset,
      filter: {
        ...(rating ? { rating } : {}),
        ...(clientUid ? { client_uid: clientUid } : {}),
      },
      sort: {
        rating: 'desc',
        date: 'desc',
      },
    }

    const response = await reviewServiceClient.post('/reviews', JSON.stringify(payload))

    if (response && response.data) {
      return {
        reviews: response.data,
      }
    }
    return {
      reviews: [],
    }
  } catch (e) {
    logger.error('getFilteredReviews(%o) failed', e.message)

    return {
      reviews: [],
    }
  }
}

export const getUserProductReview = async (clientUid, sku) => {
  try {
    const response = await getFilteredReviews(clientUid, sku)
    if (Array.isArray(response.reviews) && response.reviews?.length) {
      return {
        ok: true,
        review: response.reviews[0],
      }
    }
    return {
      ok: false, review: null,
    }
  } catch (e) {
    logger.error('getUserProductReview(%o) failed: %s', clientUid, e.message)
    return {
      ok: false,
      review: null,
    }
  }
}
