const pickupInstructions = [
  {
    icon: 'shipping',
    fragments: [
      { text: 'Dodanie na odberné miesto Packety z' },
      {
        text: 'online obchodu',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      {
        text: 'Hneď ako bude Vaša objednávka pripravená na vyzdvihnutie, Packeta Vás bude kontaktovať SMS správou, alebo E-mailom.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'Objednávku si môžete vyzdvihnúť' },
      {
        text: 'maximálne do 7 dní od jej doručenia na odberné miesto.',
        isBold: true,
      },
    ],
  },
]

export default {
  orderLabel: 'Objednávka na odberné miesto',
  unavailablePickupLabel: 'Na toto miesto nie je možné objednať',
  selectedPlaceLabel: 'Vybrané odberné miesto',
  confirmButtonLabel: 'Vyberte odberné miesto',

  getTitle() {
    switch (this.courierId) {
      case 15: // packeta
        return this.location.street
      case 115: // SK post
        // street form: '9, street, ,zip'
        // eslint-disable-next-line no-case-declarations
        const addressParts = this.location.street.split(',').map((part) => part.trim())
        if (addressParts.length !== 4) { // in case the data is different, use default
          return this.title
        }

        return `${addressParts[1]} ${addressParts[0]}`

      default:
        return this.title
    }
  },
  getSubtitle() {
    switch (this.courierId) {
      case 15: // packeta
        return [this.location.zipCode, this.location.city].filter(Boolean).join(' ')
      case 115: // SK post
        // street form: '9, street, ,zip'
        // eslint-disable-next-line no-case-declarations
        const addressParts = this.location.street.split(',').map((part) => part.trim())
        if (addressParts.length !== 4) { // in case the data is different, use default
          return [this.location.street, this.location.city].filter(Boolean).join(', ')
        }

        // eslint-disable-next-line no-case-declarations
        const formattedPostcode = `${addressParts[3].slice(0, 3)} ${addressParts[3].slice(3)}`

        return `${formattedPostcode} ${this.location.city}`

      default:
        return [this.location.street, this.location.city].filter(Boolean).join(', ')
    }
  },
  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  pins: {
    enabled: 'pin-boxes-enabled.svg',
    disabled: 'pin-boxes-disabled.svg',
    selected: 'pin-boxes-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#BA1B02',
  icon: '/img/delivery/box.svg',
}
