export default {
  name: 'PagebuilderHeading',
  functional: true,
  props: {
    item: {
      type: Object,
      default: function _default() {}
    }
  },
  render: function render(h, ctx) {
    var _item$settings;
    var item = ctx.props.item || {};
    var itemSettings = (_item$settings = item.settings) !== null && _item$settings !== void 0 ? _item$settings : {};
    if (!(itemSettings !== null && itemSettings !== void 0 && itemSettings.visible)) {
      return [];
    }
    var tag = itemSettings.tag || 'h2';
    var data = {
      class: "pagebuilder-heading",
      attrs: {
        id: "pb-".concat(item.id)
      }
    };
    return h(tag, data, item.content);
  }
};