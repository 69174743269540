<template>
  <placement-view-provider
    placement-name="product-widget"
  >
    <div
      ref="products"
      class="pagebuilder-products spinner-overlay"
      :class="[settings.cssClass, {
        'pagebuilder-products--slider': isAppearanceSlider,
        'pagebuilder-products--loading': isLoading,
      }]"
    >
      <div v-if="isLoading" class="spinner">
        <span
          class="spinner-border"
          role="status"
          aria-hidden="true"
        />
      </div>

      <div
        v-if="isAppearanceSlider && productsLength"
        class="v-slider-products"
        :class="{'v-slider-products--loading': !isSliderMounted}"
      >
        <v-carousel
          :products="products"
          :is-observed="true"
          :autoplay-interval="autoplayInterval"
          :infinite-loop="infiniteLoop"
          :max-slides-per-view="responsiveProductsLimit"
          :new-style="!hasArrowsOnSides"
          :has-more-pages="hasMorePages"
          :lead-image="leadImage"
          :per-view-auto="!!leadImage"
          @slider-mounted="onSliderMounted"
        />
      </div>

      <div v-if="!isAppearanceSlider">
        <v-listing
          v-if="products.length"
          :products="products"
        />
      </div>

      <v-products-categories
        v-if="categories.length"
        class="mt-2"
        :categories="categories"
      />

      <div
        v-if="isPaging"
        class="section mt-2 mb-2 mt-lg-5 mb-lg-5 order-6"
      >
        <v-pagination
          :total="productsTotal"
          :change-url="false"
          :current-page-number="page"
          @pageClick="pageClick"
        />
      </div>
    </div>
  </placement-view-provider>
</template>

<script>
import { mapGetters } from 'vuex'
import PlacementViewProvider from '@nsf/gtm/providers/PlacementViewProvider.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { getCategoriesByIds } from '@nsf/catalog/repositories/CategoryRepository.js'
import { getPageBuilderData } from '@nsf/cms/repositories/CmsServiceRepository.js'

const {
  base: {
    carousel: {
      carouselFullSliderMaxCount,
    },
  },

  catalog: {
    category: {
      root,
    },
  },

  rootConfig: {
    global: {
      pagination: {
        productsPerPage,
      },
    },
  },
} = useAppConfig()

export default {
  name: 'PbProducts',

  components: {
    PlacementViewProvider,
    'v-listing': () => import('@nsf/catalog/components/category/Listing/index.vue'),
    'v-carousel': () => import('@nsf/catalog/components/product/Carousel/index.vue'),
    'v-products-categories': () => import('@nsf/base/components/PageBuilder/ProductsCategories.vue'),
    'v-pagination': () => import('@nsf/base/components/Pagination.vue'),
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isSliderMounted: false,
      categories: [],
      hasMorePages: false,
      loadingProducts: false,
      maxProductsCount: carouselFullSliderMaxCount,
      page: 1,
      products: [],
      productsPerView: productsPerPage,
      productsQuery: '',
      productsTotal: 0,
      responsiveProductsLimit: undefined,
      skus: [],
    }
  },

  computed: {
    ...mapGetters('_base/resolver', {
      pageTitle: 'resolverTitle',
    }),

    settings() {
      return this.item.settings || {}
    },

    isLoading() {
      if (this.isAppearanceSlider && !this.isSliderMounted) {
        return true
      }

      return this.loadingProducts
    },

    infiniteLoop() {
      return this.settings.infiniteLoop || false
    },

    productsLength() {
      return this.products?.length || 0
    },

    leadImage() {
      return this.settings.leadImage?.src || ''
    },

    autoplay() {
      return this.settings.autoplay || false
    },

    autoplayInterval() {
      if (this.autoplay) {
        const speed = parseInt(this.settings.autoplaySpeed, 10)
        return speed || 4000
      }

      return 0
    },

    isAppearanceGrid() {
      return this.settings.appearance === 'grid' || !this.settings.appearance
    },

    isAppearanceSlider() {
      return this.settings.appearance === 'carousel'
    },

    hasArrowsOnSides() {
      return this.settings.arrowsOnSides === true
    },

    isCategories() {
      return this.settings.showCategories || false
    },

    isPaging() {
      return this.isAppearanceGrid && this.productsTotal > productsPerPage
    },

    limit() {
      return this.settings.limit || productsPerPage
    },

    sliceLimit() {
      if (this.isAppearanceGrid) {
        return Math.min(productsPerPage, this.limit)
      }
      return this.limit
    },

    pagesTotal() {
      return Math.ceil(this.productsTotal / this.maxProductsCount)
    },

    size() {
      return this.maxProductsCount - (this.products.length % this.maxProductsCount)
    },
  },

  async created() {
    let products = []

    if (!this.item.pagebuilderData) {
      this.loadProductsForPage(this.page)
      return
    }

    products = this.item.pagebuilderData?.items?.slice(0, this.sliceLimit) || []

    this.products = products.map((p) => {
      const content = p?.placementsProducts?.[0]?.content

      if (content) {
        return {
          ...p,
          content,
        }
      }

      return p
    })

    this.productsTotal = Math.min(this.item.pagebuilderData?.total, this.limit) || 0
  },

  async fetch() {
    if (this.isCategories) {
      await this.loadCategories()
    }
  },

  mounted() {
    if (this.isAppearanceSlider) {
      const minWidthForProduct = 180.0
      const sliderWidth = this.$el?.clientWidth || 1140

      this.responsiveProductsLimit = Math.max(1, Math.floor(sliderWidth / minWidthForProduct))
    }
  },

  methods: {
    // used for grid appearance
    async loadProductsForPage(page = 1) {
      const data = {
        pager: {
          page,
          limit: productsPerPage,
        },
        type: 'Products',
      }

      data.filters = this.settings.filters || {}
      data.sorters = this.settings.sorters || []
      const response = await getPageBuilderData(data)

      if (response && response.items?.length > 0) {
        this.products = response.items.slice(0, this.sliceLimit).map((p) => {
          const content = p?.placementsProducts?.[0]?.content

          if (content) {
            return {
              ...p,
              content,
            }
          }

          return p
        })

        this.productsTotal = Math.min(response.total, this.limit) || 0
      }
    },

    pageClick(page) {
      this.page = page
      this.loadProductsForPage(page)
      this.scrollTop()
    },

    scrollTop() {
      const target = this.$refs.products

      let offset = 30
      if (window.innerWidth < 768) {
        offset += document.querySelector('#header').clientHeight
      }

      const bodyRect = document.body.getBoundingClientRect().top
      const targetRect = target.getBoundingClientRect().top
      const targetPosition = targetRect - bodyRect
      const offsetPosition = targetPosition - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    },

    async onSliderMounted() {
      await this.$nextTick()
      this.isSliderMounted = true
    },

    async loadCategories() {
      const categoryIds = [
        ...new Set(this.products?.flatMap((product) => product.categoryIds) || []),
      ].filter((id) => id !== root?.id)
      if (!categoryIds?.length) {
        return
      }
      const { categories } = await getCategoriesByIds(categoryIds)
      this.categories = categories.filter((cat) => cat.level === 4)
    },
  },
}
</script>

<style lang="scss">
/* stylelint-disable */
.pagebuilder-products {
  position: relative;
  width: 100%;
  overflow: hidden;

  @media only screen and (max-width: 390px) {
    .keen-slider__slide {
      min-width: 200px;
      max-width: 200px;
    }
  }

  &--slider {
    min-height: 532px;
    transition: all 0.5s ease-in-out;
  }

  .spinner {
    display: none;
  }

  .spinner-border {
    width: 64px;
    height: 64px;
    border-width: 5px;
  }

  &--loading {
    background-color: #fbfbfb;
    color: rgba(0, 0, 0, 0.6) !important;
    opacity: 0.5;

    .spinner {
      z-index: 2;
      position: absolute;
      width: 100%;
      height: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  .v-slider-products {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;

    &--loading {
      opacity: 0;

      .tile.tile--carousel {
        max-height: 440px;
      }
    }

    .tile.tile--carousel {
      min-height: 440px;
    }
  }
}

/* stylelint-enable */
</style>
