<template>
  <div class="pagebuilder-show-more">
    <v-render-content v-show="contentVisible" :items="items" />
    <v-button @click="onClickShowMore">
      {{ buttonText }}
    </v-button>
  </div>
</template>

<script>
import Button from '@nsf/ui-library/components/atoms/Button.vue'
import RenderContent from './RenderContent.vue'

export default {
  name: 'PagebuilderShowMore',

  components: {
    'v-render-content': RenderContent,
    'v-button': Button,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      contentVisible: false,
    }
  },

  computed: {
    buttonText() {
      if (this.contentVisible) {
        return this.$t('cms.Show less')
      }

      return this.$t('cms.Show more')
    },
  },

  methods: {
    async onClickShowMore() {
      this.contentVisible = !this.contentVisible

      // we will emit resize event so carousel in hidden area will recalculate width after showing up
      await this.$nextTick()
      window.dispatchEvent(new Event('resize'))
    },
  },
}
</script>

<style lang="scss">
.pagebuilder-show-more {
  text-align: center;

  .pb-render-content {
    text-align: left;
  }
}
</style>
