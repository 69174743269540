import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import PbColumn from './Column.vue';
export default {
  name: 'PagebuilderColumns',
  functional: true,
  props: {
    item: {
      type: Object,
      default: function _default() {}
    }
  },
  render: function render(h, ctx) {
    var _item$settings, _item$children, _item$settings2;
    var item = ctx.props.item || {};
    if (!(item !== null && item !== void 0 && (_item$settings = item.settings) !== null && _item$settings !== void 0 && _item$settings.visible) || !(item !== null && item !== void 0 && (_item$children = item.children) !== null && _item$children !== void 0 && _item$children.length)) {
      return [];
    }
    var items = [];
    var _iterator = _createForOfIteratorHelper(item.children),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _child$settings;
        var child = _step.value;
        if (!(child !== null && child !== void 0 && (_child$settings = child.settings) !== null && _child$settings !== void 0 && _child$settings.visible)) {
          continue;
        }
        items.push(h(PbColumn, {
          props: {
            item: child
          }
        }));
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    if (!items.length) {
      return [];
    }
    var classes = ['pb-columns row'];
    if (item !== null && item !== void 0 && (_item$settings2 = item.settings) !== null && _item$settings2 !== void 0 && _item$settings2.cssClass) {
      classes.push(item.settings.cssClass);
    }
    return h('div', {
      class: classes.join(' ')
    }, items);
  }
};