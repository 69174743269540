<template>
  <ol
    v-if="slides.length"
    ref="textCarouselIndicators"
    class="d-flex text-carousel-indicators position-relative"
    aria-hidden="true"
  >
    <v-tab
      v-for="(slide,index) in slides"
      ref="textCarouselItems"
      :key="`data-slide-to-${index}`"
      :slide="slide"
      :is-active="activeSlide === index"
      @click.native="changeSlide(index)"
    />
  </ol>
</template>
<script>
import Tab from './Tab.vue'

export default {
  name: 'SliderNavigation',
  components: {
    'v-tab': Tab,
  },
  props: {
    activeSlide: {
      type: Number,
      required: true,
    },
    slides: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    activeSlide: {
      handler(activeSlideIndex) {
        this.scrollToActiveLink(activeSlideIndex)
      },
      immediate: true,
    },
  },
  methods: {
    changeSlide(index) {
      this.$emit('changeSlide', index)
    },
    scrollToActiveLink(activeIndicatorIndex) {
      const activeIndicator = this.$refs.textCarouselItems?.[activeIndicatorIndex]
      const itemOffsetLeft = activeIndicator?.$el?.offsetLeft
      this.$refs.textCarouselIndicators?.scrollTo({ left: itemOffsetLeft, behavior: 'smooth' })
    },
  },
}
</script>
