<template>
  <div :class="className">
    <div class="section mb-3 container p-0">
      <div class="carousel carousel-slider slide">
        <div class="carousel-inner">
          <Slide
            :slide="item"
            :class="{
              'active' : true,
            }"
            should-start-loading
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Slide from './Slider/Slide.vue'

export default {
  name: 'PbBanner',

  components: {
    Slide,
  },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    settings() {
      return this.item.settings || {}
    },

    className() {
      return `${this.settings.cssClass ? `${this.settings.cssClass} ` : ''}pagebuilder-banner`
    },
  },
}
</script>

<style scoped lang="css">
/* stylelint-disable */
.big_blocks .commercial-image {
  border-radius: 7px;
}
/* stylelint-enable */
</style>
