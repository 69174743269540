import "core-js/modules/es.array.join.js";
import { convertSettingsToInlineStyles } from '@nsf/utils/PagebuilderUtils.js';
export default {
  name: 'PagebuilderDivider',
  functional: true,
  props: {
    item: {
      type: Object,
      default: function _default() {}
    }
  },
  render: function render(h, ctx) {
    var _ctx$props$item, _ctx$props$item$setti, _ctx$props$item2, _ctx$props$item3, _ctx$props$item3$sett, _ctx$props$item4, _ctx$props$item4$sett;
    if (!((_ctx$props$item = ctx.props.item) !== null && _ctx$props$item !== void 0 && (_ctx$props$item$setti = _ctx$props$item.settings) !== null && _ctx$props$item$setti !== void 0 && _ctx$props$item$setti.visible)) {
      return [];
    }
    var inlineStyles = convertSettingsToInlineStyles(((_ctx$props$item2 = ctx.props.item) === null || _ctx$props$item2 === void 0 ? void 0 : _ctx$props$item2.settings) || {});
    var alignment = ((_ctx$props$item3 = ctx.props.item) === null || _ctx$props$item3 === void 0 ? void 0 : (_ctx$props$item3$sett = _ctx$props$item3.settings) === null || _ctx$props$item3$sett === void 0 ? void 0 : _ctx$props$item3$sett.horizontalAlignment) || 'left';
    var hr = h('hr', {
      style: inlineStyles
    });
    var classes = ["pb-divider pb-divider-".concat(alignment)];
    if ((_ctx$props$item4 = ctx.props.item) !== null && _ctx$props$item4 !== void 0 && (_ctx$props$item4$sett = _ctx$props$item4.settings) !== null && _ctx$props$item4$sett !== void 0 && _ctx$props$item4$sett.cssClass) {
      classes.push(ctx.props.item.settings.cssClass);
    }
    return h('div', {
      class: classes.join(' ')
    }, [hr]);
  }
};