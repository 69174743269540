var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-link',{staticClass:"carousel-item container-fluid p-0",attrs:{"new-window":_vm.linkTarget === '_blank',"to":_vm.linkHref,"data-test-id":"carousel-nav-link","aria-label":_vm.name},on:{"click":_vm.onClick}},[(_vm.shouldStartLoading || _vm.isImageLoaded)?_c('v-lazy-image',{class:[
      'carousel-item__image',
      {
        'carousel-item__image-desktop': _vm.imageDesktop,
        'carouse-item__image-mobile': _vm.imageMobile
      }
    ],style:(((!_vm.imageDesktop && !_vm.imageMobile) ? { opacity: 0 } : {})),attrs:{"alt":_vm.name,"opts":_vm.image,"is-not-lazy":_vm.isNotLazy,"pixel-density":[2]},on:{"load":_vm.onLoad}}):_vm._e(),_vm._v(" "),_c('div',{class:['carousel-item__wrapper row m-0 p-4', _vm.appearance]},[_c('div',{staticClass:"carousel-item__overlay"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.content)}}),_vm._v(" "),(_vm.buttonText)?_c('v-button',{attrs:{"variant":_vm.buttonType}},[_vm._v("\n        "+_vm._s(_vm.buttonText)+"\n      ")]):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }