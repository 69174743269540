<template>
  <div :class="className" :style="inlineStyle">
    <div class="section mb-3 container p-0">
      <div
        class="carousel carousel-slider slide"
        data-ride="carousel"
        data-test-id="carousel-wrapper"
      >
        <div v-if="showArrows" class="carousel-slider__btns__wrap d-md-block">
          <div class="carousel-slider__btns">
            <div
              class="carousel-slider__btn carousel-slider__btn--left"
              @click="onSwipeRight"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon"
                aria-hidden="true"
                viewBox="-391.5 288.8 10.2 18.9"
              >
                <title>left</title>
                <path
                  fill="#ffffff"
                  d="M-391.3,297.7l8.7-8.7c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1l-8.2,8.2l8.2,8.1c0.3,0.3,0.3,0.8,0,1.1  c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2l-8.7-8.7C-391.6,298.5-391.6,298-391.3,297.7z"
                />
              </svg>
            </div>
            <div
              class="carousel-slider__btn carousel-slider__btn--right"
              @click="onSwipeLeft"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon"
                aria-hidden="true"
                viewBox="-391.5 288.8 10.2 18.9"
              >
                <title>right</title>
                <path
                  fill="#ffffff"
                  d="M -381.5 297.7 l -8.7 -8.7 c -0.3 -0.3 -0.8 -0.3 -1.1 0 s -0.3 0.8 0 1.1 l 8.2 8.2 l -8.2 8.1 c -0.3 0.3 -0.3 0.8 0 1.1 c 0.1 0.1 0.3 0.2 0.5 0.2 c 0.2 0 0.4 -0.1 0.5 -0.2 l 8.7 -8.7 C -381.3 298.5 -381.3 298 -381.5 297.7 Z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div
          class="carousel-inner"
          @mouseenter="stopRotation()"
          @mouseleave="continueRotation()"
        >
          <Slide
            v-for="(slide,index) in slides"
            :key="`slide-${index}`"
            v-touch:swipe.left="onSwipeLeft"
            v-touch:swipe.right="onSwipeRight"
            :slide-index="index + 1"
            :slide="slide"
            :should-start-loading="shouldSlideLoadImage(index)"
            :is-not-lazy="isFirst(index)"
            :class="{
              'active' : isActive(index),
              'carousel-item-next' : !isRotationStopped && isNext(index),
              'carousel-item-prev' : !isRotationStopped && isPrev(index),
              'lastActive': lastActiveSlide === index
            }"
          />
        </div>

        <Navigation
          v-if="showDots"
          :slides="slides"
          :active-slide="activeSlide"
          @changeSlide="changeSlide"
        />
      </div>
    </div>
  </div>
</template>
<script>

import { convertSettingsToInlineStyles } from '@nsf/utils/PagebuilderUtils.js'
import Slide from './Slider/Slide.vue'
import Navigation from './Slider/Navigation/index.vue'

export default {
  name: 'PbSlider',

  components: {
    Slide,
    Navigation,
  },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      activeSlide: 0,
      isRotationStopped: false,
      lastActiveSlide: 0,
    }
  },

  computed: {
    slides() {
      return this.item?.children || []
    },

    settings() {
      return this.item.settings || {}
    },

    className() {
      return `${this.settings.cssClass ? `${this.settings.cssClass} ` : ''}pagebuilder-slider`
    },

    appearance() {
      return this.item.settings?.appearance || 'small_blocks'
    },

    inlineStyle() {
      return convertSettingsToInlineStyles(this.item?.settings || {})
    },

    autoplay() {
      return this.settings.autoplay || false
    },

    autoplaySpeed() {
      if (this.autoplay) {
        const speed = parseInt(this.settings.autoplaySpeed, 10)
        return speed || 4000
      }

      return 0
    },

    infinityLoop() {
      return this.settings.infiniteLoop
    },

    fade() {
      return this.settings.fade
    },

    showArrows() {
      return this.settings.showArrows
    },

    showDots() {
      return this.settings.showDots
    },
  },

  mounted() {
    this.$nextTick(() => {
      setTimeout(() => this.startRotation(), this.autoplaySpeed)
    })
  },

  beforeDestroy() {
    this.stopRotation()
  },

  methods: {
    isFirst(index) {
      return index === this.firstVisibleSlideIndex
    },

    isActive(index) {
      return index === this.activeSlide
    },

    isNext(index) {
      return index > this.activeSlide
    },

    isPrev(index) {
      return index < this.activeSlide
    },

    onSwipeLeft() {
      if (this.activeSlide < this.slides.length - 1) {
        this.changeSlide(this.activeSlide + 1)
      } else if (this.infinityLoop) {
        this.changeSlide(0)
      }
    },

    onSwipeRight() {
      if (this.activeSlide > 0) {
        this.changeSlide(this.activeSlide - 1)
      } else if (this.infinityLoop) {
        this.changeSlide(this.slides.length - 1)
      }
    },

    async changeSlide(index) {
      if (this.isRotationStopped) {
        return
      }

      this.lastActiveSlide = this.activeSlide
      this.activeSlide = index
      clearInterval(this.interval)
      this.interval = this.resetInterval()

      await this.$nextTick() // waiting for setting active indicator
      this.scrollToActiveLink()
    },

    resetInterval() {
      return this.autoplay
        ? setInterval(() => {
          this.changeSlide((this.activeSlide + 1) % this.slides.length)
        }, this.autoplaySpeed)
        : null
    },

    startRotation() {
      this.interval = this.resetInterval()
      this.changeSlide(this.activeSlide)
    },

    continueRotation() {
      this.isRotationStopped = false
      this.startRotation()
    },

    stopRotation() {
      clearInterval(this.interval)
      this.isRotationStopped = true
    },

    scrollToActiveLink() {
      const carouselIndicators = this.$refs.indicators
      if (carouselIndicators?.length < 1) {
        return
      }

      const activeIndicator = this.$refs.indicators?.querySelector('.active')
      if (!activeIndicator) {
        return
      }

      const offset = ((carouselIndicators.offsetWidth - activeIndicator.offsetWidth) / 2)
      const scrollOffset = activeIndicator.offsetLeft - offset
      if (carouselIndicators.scrollWidth > carouselIndicators.offsetWidth) {
        carouselIndicators.scrollLeft = scrollOffset
      }
    },

    shouldSlideLoadImage(index) {
      return this.isActive(index) || index === this.activeSlide + 1
    },
  },
}
</script>

<style scoped lang="css">
/* stylelint-disable */
.big_blocks .commercial-image {
  border-radius: 7px;
}
/* stylelint-enable */
</style>
