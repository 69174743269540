import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.link.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import Button from '@nsf/ui-library/components/atoms/Button.vue';
import { convertSettingsToInlineStyles } from '@nsf/utils/PagebuilderUtils.js';
import { setFlexAlignment } from '@nsf/base/utils/PagebuilderStyleUtils.js';
export default {
  name: 'PagebuilderButtons',
  functional: true,
  props: {
    item: {
      type: Object,
      default: function _default() {}
    }
  },
  render: function render(h, ctx) {
    var _ctx$props$item, _ctx$props$item2, _ctx$props$item2$sett, _ctx$props$item3, _ctx$props$item4, _ctx$props$item4$sett, _ctx$props$item5, _ctx$props$item5$sett, _ctx$props$item6, _ctx$props$item6$sett, _ctx$props$item7, _ctx$props$item7$sett;
    var items = [];
    var children = ((_ctx$props$item = ctx.props.item) === null || _ctx$props$item === void 0 ? void 0 : _ctx$props$item.children) || [];
    if (!((_ctx$props$item2 = ctx.props.item) !== null && _ctx$props$item2 !== void 0 && (_ctx$props$item2$sett = _ctx$props$item2.settings) !== null && _ctx$props$item2$sett !== void 0 && _ctx$props$item2$sett.visible)) {
      return [];
    }
    var _iterator = _createForOfIteratorHelper(children),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _child$settings, _child$settings2, _child$settings3, _child$settings4;
        var child = _step.value;
        if (!(child !== null && child !== void 0 && (_child$settings = child.settings) !== null && _child$settings !== void 0 && _child$settings.visible)) {
          continue;
        }
        if ((child === null || child === void 0 ? void 0 : (_child$settings2 = child.settings) === null || _child$settings2 === void 0 ? void 0 : _child$settings2.type) === 'link') {
          child.settings.type = 'naked';
        }
        items.push(h(Button, {
          props: {
            variant: child === null || child === void 0 ? void 0 : (_child$settings3 = child.settings) === null || _child$settings3 === void 0 ? void 0 : _child$settings3.type,
            link: child.settings.link
          },
          attrs: {
            target: (_child$settings4 = child.settings) !== null && _child$settings4 !== void 0 && _child$settings4.newTab ? '_blank' : undefined
          }
        }, [child.content]));
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    var inlineStyles = convertSettingsToInlineStyles(((_ctx$props$item3 = ctx.props.item) === null || _ctx$props$item3 === void 0 ? void 0 : _ctx$props$item3.settings) || {});
    var classes = ['pb-buttons'];
    classes.push("pb-buttons-".concat(((_ctx$props$item4 = ctx.props.item) === null || _ctx$props$item4 === void 0 ? void 0 : (_ctx$props$item4$sett = _ctx$props$item4.settings) === null || _ctx$props$item4$sett === void 0 ? void 0 : _ctx$props$item4$sett.appearance) || 'inline'));
    classes.push(setFlexAlignment(((_ctx$props$item5 = ctx.props.item) === null || _ctx$props$item5 === void 0 ? void 0 : (_ctx$props$item5$sett = _ctx$props$item5.settings) === null || _ctx$props$item5$sett === void 0 ? void 0 : _ctx$props$item5$sett.horizontalAlignment) || 'left'));
    if ((_ctx$props$item6 = ctx.props.item) !== null && _ctx$props$item6 !== void 0 && (_ctx$props$item6$sett = _ctx$props$item6.settings) !== null && _ctx$props$item6$sett !== void 0 && _ctx$props$item6$sett.isSameWidth) {
      classes.push('pb-buttons-same-width');
    }
    if ((_ctx$props$item7 = ctx.props.item) !== null && _ctx$props$item7 !== void 0 && (_ctx$props$item7$sett = _ctx$props$item7.settings) !== null && _ctx$props$item7$sett !== void 0 && _ctx$props$item7$sett.cssClass) {
      classes.push(ctx.props.item.settings.cssClass);
    }
    return h('div', {
      class: classes.join(' '),
      style: inlineStyles
    }, items);
  }
};