<template>
  <v-link
    :new-window="linkTarget === '_blank'"
    :to="linkHref"
    class="carousel-item container-fluid p-0"
    data-test-id="carousel-nav-link"
    :aria-label="name"
    @click="onClick"
  >
    <v-lazy-image
      v-if="shouldStartLoading || isImageLoaded"
      :alt="name"
      :class="[
        'carousel-item__image',
        {
          'carousel-item__image-desktop': imageDesktop,
          'carouse-item__image-mobile': imageMobile
        }
      ]"
      :opts="image"
      :is-not-lazy="isNotLazy"
      :style="((!imageDesktop && !imageMobile) ? { opacity: 0 } : {})"
      :pixel-density="[2]"
      @load="onLoad"
    />

    <div :class="['carousel-item__wrapper row m-0 p-4', appearance]">
      <div class="carousel-item__overlay">
        <div v-html="content" />
        <v-button v-if="buttonText" :variant="buttonType">
          {{ buttonText }}
        </v-button>
      </div>
    </div>
  </v-link>
</template>

<script>
import logger from '@nsf/base/logger.js'
import PlacementViewObserver from '@nsf/gtm/mixins/PlacementViewObserver.js'
import { getBannerPlacementType, getBannerPlacementData } from '@nsf/gtm/utils/banner.js'
import Button from '@nsf/ui-library/components/atoms/Button.vue'

export default {
  name: 'SliderSlide',

  components: {
    'v-button': Button,
  },

  mixins: [PlacementViewObserver],

  inject: ['WindowDimensionProvider'],

  props: {
    /** Slide element data */
    slide: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    /** Choose whether the slide should start loading the image immediately. */
    shouldStartLoading: {
      type: Boolean,
      default: false,
    },

    /** Choose whether you want to disable lazy load the image. */
    isNotLazy: {
      type: Boolean,
      default: false,
    },

    slideIndex: {
      type: Number,
      default: 1,
    },
  },

  data: () => ({
    /** Image of the slide is already loaded. */
    isImageLoaded: false,
  }),

  computed: {
    item() {
      return this.slide || {}
    },

    settings() {
      return this.item.settings || {}
    },

    mobileSettings() {
      return this.item.mobileSettings || {}
    },

    appearance() {
      return this.settings.appearance || 'poster'
    },

    content() {
      return this.item.content || ''
    },

    image() {
      // Specify media queries in every item - with it the "preload" in LazyImage will preload the needed format only
      return [
        {
          path: this.imageMobile,
          w: 360,
          h: 330,
          media: this.WindowDimensionProvider.forXsMobile,
        },
        {
          path: this.imageDesktop,
          w: 1140,
          h: 330,
          media: this.WindowDimensionProvider.fromXsMobile,
        },
      ]
    },

    imageDesktop() {
      return this.settings.backgroundImage ?? ''
    },

    imageMobile() {
      return this.mobileSettings.backgroundImage ?? ''
    },

    images() {
      const backgroundImages = this.slideWrapper?.['data-background-images']

      try {
        return backgroundImages
          ? JSON.parse(backgroundImages.replace(/\\/g, ''))
          : null
      } catch (e) {
        logger.withTag('PageBuilder').error('Slider slide - JSON parse error: %s', e.message)
        return null
      }
    },

    link() {
      return this.settings.link
    },

    buttonText() {
      return this.settings.buttonText
    },

    buttonType() {
      return this.settings.buttonType
    },

    linkHref() {
      const {
        appUrl,
        magentoUrlInternal,
      } = this.$config.public
      return this.link?.replace(magentoUrlInternal, appUrl) ?? ''
    },

    linkTarget() {
      return this.link?.target ?? ''
    },

    name() {
      return this.settings.name
    },
  },

  methods: {
    onLoad() {
      this.isImageLoaded = true
    },

    onClick() {
      this.$PlacementView?.pushPlacementClickEvent({
        data: [this.getPlacementObjectData()],
        type: this.getPlacementObjectType(),
        isLoggedIn: this.isLoggedIn,
      })
    },

    getPlacementObjectType() {
      return getBannerPlacementType()
    },

    getPlacementObjectData() {
      const item = this.slide.data?.banner || {}
      if (!item.name && this.slide['data-slide-name']) {
        item.name = this.slide['data-slide-name']
      }

      return getBannerPlacementData(item, this.slideIndex)
    },
  },
}
</script>
<style lang="scss" scoped>
.carousel-hp {
  .carousel-item {
    position: relative;
    text-decoration: none;
    color: unset;
    cursor: pointer;
    /* stylelint-disable-next-line max-nesting-depth, selector-max-compound-selectors,
    selector-pseudo-element-no-unknown */
    ::v-deep img {
      width: 100%;
      height: auto;
      aspect-ratio: 360 / 330;
      @media screen and (min-width: $md-min) {
        aspect-ratio: 1140 / 330;
      }
    }
  }
}

.carousel-item:not(.active):not(.lastActive) {
  opacity: 0;
}

.carousel-item__wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 360 / 330;
  background: transparent !important;
  @media screen and (min-width: $md-min) {
    aspect-ratio: 1140 / 330;
  }
}

@media (min-width: $md-min) {
  .carousel-item__wrapper:not(.default) .carousel-item__overlay {
    width: 33.33%;
  }

  .carousel-item__wrapper.collage-centered {
    justify-content: center;
  }

  .carousel-item__wrapper.collage-left {
    justify-content: flex-start;
  }

  .carousel-item__wrapper.collage-right {
    justify-content: flex-end;
  }
}
</style>
