<template>
  <section v-if="articles.length" ref="articles">
    <div
      v-if="isArticlesList || isCarousel"
      class="pagebuilder-articles"
    >
      <div
        class="pagebuilder-articles-anchor"
      />

      <ArticlesList
        v-if="isArticlesList"
        :articles="articles"
        :title="title"
        :show-title="showTitle"
        :count="count"
        :per-page="articleLimit"
        :is-paging="isPagingAllowed"
        :alignment="alignment"
        :change-url="false"
        :current-page="page"
        @paginationPageClick="onPaginationPageClick"
      />

      <template v-else>
        <h2
          v-if="showTitle"
          class="carousel-headline"
        >
          {{ title }}
        </h2>

        <v-articles-carousel
          :articles="articles"
        />
      </template>
    </div>

    <div
      v-else-if="isMainAndNewest"
      class="pagebuilder-recent-articles"
    >
      <RecentArticles
        v-if="articles.length"
        :articles-obj="{
          title: title,
          showTitle: showTitle,
          articles
        }"
      />
    </div>
  </section>
</template>

<script>
import { getPageBuilderData } from '@nsf/cms/repositories/CmsServiceRepository.js'
import { mapDataToArticle } from '@nsf/articles/mappers/ArticleMapper.js'

export default {
  name: 'PbArticles',

  components: {
    'ArticlesList': () => import('@nsf/articles/components/ArticlesList/index.vue'),
    'RecentArticles': () => import('@nsf/articles/components/RecentArticles/index.vue'),
    'v-articles-carousel': () => import('@nsf/base/components/PageBuilder/Articles/Carousel.vue'),
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      articles: [],
      count: 0,
      index: 0,
      page: 1,
    }
  },

  computed: {
    settings() {
      return this.item.settings || {}
    },

    isArticlesList() {
      return this.settings?.appearance === 'article_list' || !this.settings?.appearance
    },

    isCarousel() {
      return this.settings?.appearance === 'carousel'
    },

    isMainAndNewest() {
      return this.settings?.appearance === 'featured_with_newest'
    },

    isPagingAllowed() {
      return this.settings?.hasPaging
    },

    title() {
      return this.settings?.title
    },

    showTitle() {
      return this.settings?.showTitle
    },

    alignment() {
      return this.settings?.horizontalAlignment
    },

    articleLimit() {
      const limit = parseInt(this.settings?.limit, 10)

      if (this.isMainAndNewest) {
        // display 1 main and 5 newest
        return 6
      }

      if (this.isCarousel) {
        if (!limit || limit > 12) {
          return 12
        }

        return limit
      }

      if (limit === 0) {
        return null
      }

      return limit
    },

    cssClass() {
      const classes = ['pagebuilder-articles']

      if (this.settings?.cssClass) {
        classes.push(this.settings.cssClass)
      }

      return classes.join(' ')
    },
  },

  created() {
    if (!this.item.pagebuilderData) {
      this.loadArticlesForPage(this.page)
    }

    this.articles = mapDataToArticle(this.item.pagebuilderData?.items?.slice(0, this.articleLimit) || [])
    this.count = this.item.pagebuilderData?.total || 0
  },

  methods: {
    async loadArticlesForPage(page = 1) {
      const data = {
        pager: {
          page,
          limit: this.articleLimit,
        },
        type: 'Articles',
      }

      data.filters = this.settings.filters || {}
      data.sorters = this.settings.sorters || []
      const response = await getPageBuilderData(data)

      if (response && response.items?.length > 0) {
        this.articles = mapDataToArticle(response.items?.slice(0, this.articleLimit) || [])
        this.count = response.total || 0
      }
    },

    getCurrentPage() {
      if (this.pageWithId) {
        return Number(this.$route.query[this.pageWithId]) || 1
      }
      return Number(this.$route.query?.page) || 1
    },

    scrollTop() {
      const target = this.$refs.articles

      let offset = 30
      if (window.innerWidth < 768) {
        offset += document.querySelector('#header').clientHeight
      }

      const bodyRect = document.body.getBoundingClientRect().top
      const targetRect = target.getBoundingClientRect().top
      const targetPosition = targetRect - bodyRect
      const offsetPosition = targetPosition - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    },

    async onPaginationPageClick(page) {
      await this.loadArticlesForPage(page)
      this.page = page
      this.scrollTop()
    },
  },
}
</script>

<style lang="scss">
/*! purgecss start ignore */
/* stylelint-disable */

.pagebuilder-articles {
  position: relative;

  .carousel-headline {
    @include headline-small-spaced;
    margin-top: 20px;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.88);
  }

  &-anchor {
    position: absolute;
    top: -192px;
    height: 192px;
    pointer-events: none;

    @media screen and (min-width: 992px) {
      top: -64px;
      height: 64px;
    }
  }

  .article-list[data-alignment='center'] {
    .article-list {
      &__title {
        text-align: center;
      }

      &__items,
      &__pagination {
        display: flex;
        justify-content: center;
      }
    }
  }

  .article-list[data-alignment='right'] {
    .article-list {
      &__title {
        text-align: right;
      }

      &__items,
      &__pagination {
        display: flex;
        justify-content: right;
      }
    }
  }
}
.pagebuilder-recent-articles {
  min-height: 520px;
}

html[lang="pl-PL"] {
  .pagebuilder-recent-articles {
    min-height: 580px;
  }
}
/* stylelint-enable */
/*! purgecss end ignore */
</style>
