import { renderChildren } from '@nsf/base/components/PageBuilder/index.js';
import PagebuilderRender from '@nsf/cms/components/Pagebuilder/PagebuilderRender.vue';
export default {
  name: 'CustomWidget',
  functional: true,
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  render: function render(h, ctx) {
    var _ctx$props, _ctx$props$item;
    var renderedChildren = renderChildren(h, ctx, ctx.props.data.children || []);
    if ((_ctx$props = ctx.props) !== null && _ctx$props !== void 0 && (_ctx$props$item = _ctx$props.item) !== null && _ctx$props$item !== void 0 && _ctx$props$item.pagebuilderObject) {
      renderedChildren = h(PagebuilderRender, {
        props: {
          pagebuilderObject: ctx.props.item.pagebuilderObject,
          noContainer: true
        }
      });
    }
    return h('div', {
      class: 'text'
    }, [h('h2', ctx.parent.$i18n.t('global.pageBuilder.Benefit with card')), h('div', {
      class: 'mt-2 box',
      props: {
        data: ctx.props.data
      }
    }, [renderedChildren])]);
  }
};