import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import Extend from '@nsf/base/components/Transitions/Extend.vue';
import { convertSettingsToInlineStyles, getComponent, getTextContentFromPagebuilderItem } from '@nsf/utils/PagebuilderUtils.js';
export default {
  name: 'PagebuilderAccordion',
  props: {
    item: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      expanded: false,
      buttonClass: 'btn btn-link mb-2 mr-1 shadow-none px-0 text-left'
    };
  },
  computed: {
    questionTag: function questionTag() {
      var _this$item, _this$item$settings, _this$item2, _this$item2$settings;
      if (((_this$item = this.item) === null || _this$item === void 0 ? void 0 : (_this$item$settings = _this$item.settings) === null || _this$item$settings === void 0 ? void 0 : _this$item$settings.captionLevel) === 'normal') {
        return 'div';
      }
      return ((_this$item2 = this.item) === null || _this$item2 === void 0 ? void 0 : (_this$item2$settings = _this$item2.settings) === null || _this$item2$settings === void 0 ? void 0 : _this$item2$settings.captionLevel) || 'div';
    },
    caption: function caption() {
      var _this$item3, _this$item3$settings;
      return (_this$item3 = this.item) === null || _this$item3 === void 0 ? void 0 : (_this$item3$settings = _this$item3.settings) === null || _this$item3$settings === void 0 ? void 0 : _this$item3$settings.caption;
    },
    answerText: function answerText() {
      return getTextContentFromPagebuilderItem(this.item);
    }
  },
  methods: {
    toggle: function toggle() {
      this.expanded = !this.expanded;
    },
    keydown: function keydown(event) {
      if (event.keyCode === 32 || event.keyCode === 13) {
        this.toggle();
        event.preventDefault();
      }
    },
    getSchemaJsonLd: function getSchemaJsonLd() {
      return {
        '@context': 'https://schema.org',
        '@type': 'Question',
        'text': this.questionTitle,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': this.answerText
        }
      };
    }
  },
  render: function render(h) {
    var accordion = this.item;
    var accordionSettings = accordion.settings || {};
    if (!accordionSettings.visible) {
      return [];
    }
    var items = [];
    var children = accordion.children || [];
    var _iterator = _createForOfIteratorHelper(children),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _child$settings;
        var child = _step.value;
        if (!(child !== null && child !== void 0 && (_child$settings = child.settings) !== null && _child$settings !== void 0 && _child$settings.visible)) {
          continue;
        }
        items.push(h(getComponent(child.type), {
          props: {
            item: child
          }
        }));
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    var button = h(this.questionTag, {
      class: 'accordion-title'
    }, accordionSettings.caption);
    var accordionElement = h(Extend, {
      props: {
        expanded: this.expanded,
        el: 'div'
      },
      class: 'd-xl-block'
    }, items);
    var inlineStyles = convertSettingsToInlineStyles(accordionSettings, {
      padding: true
    });
    var classes = ['pagebuilder-accordion'];
    if (this.expanded) {
      classes.push('accordion-expanded');
    }
    if (accordionSettings.cssClass) {
      classes.push(accordionSettings.cssClass);
    }
    var data = {
      on: {
        click: this.toggle,
        keydown: this.keydown
      },
      class: classes.join(' '),
      attrs: {
        tabindex: '0',
        id: accordion.id
      },
      props: {
        'aria-expanded': this.expanded
      },
      style: inlineStyles
    };
    var innerAccordion = h('div', {
      class: 'accordion-inner'
    }, [button, accordionElement]);
    var chevronTop = h('svg', {
      class: 'chevron-top'
    }, [h('use', {
      attrs: {
        'xlink:href': '/images/core/icons.svg#chevron-left'
      }
    })]);
    var schemaElement = null;
    if (accordionSettings.usage === 'faq') {
      schemaElement = h('script', {
        attrs: {
          type: 'application/ld+json'
        },
        domProps: {
          innerHTML: "".concat(JSON.stringify(this.getSchemaJsonLd()))
        }
      });
    }
    return h('div', data, [chevronTop, innerAccordion, schemaElement]);
  }
};