<template functional>
  <div
    v-if="$options.isVisible(props.item)"
    :class="$options.cssClass(props.item)"
    :style="$options.inlineStyle(props.item)"
    v-html="props.item.content"
  />
</template>

<script>

import { convertSettingsToInlineStyles } from '@nsf/utils/PagebuilderUtils.js'

export default {
  name: 'PagebuilderHtml',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  inlineStyle(item) {
    return convertSettingsToInlineStyles(item?.settings || {})
  },

  isVisible(item) {
    return item?.settings?.visible
  },

  cssClass(item) {
    const classes = ['pb-html']

    if (item?.settings?.cssClass) {
      classes.push(item.settings.cssClass)
    }

    return classes.join(' ')
  },
}
</script>
