import logger from '@nsf/base/logger.js'

export default {
  computed: {
    transEmailIdentCustom1Email() {
      return this.config.transEmailIdentCustom1Email
    },

    generalStoreInformationPhone() {
      return this.config.generalStoreInformationPhone
    },

    cmsPageDataSource() {
      return this.config.cmsPageDataSource
    },

    copyright() {
      return `© ${new Date().getFullYear()} ${this.$t('base.footer.All rights reserved')}`
    },

    drmaxNsfMobileApp1() {
      return this.config.drmaxNsfMobileApp1
    },

    drmaxNsfMobileApp2() {
      return this.config.drmaxNsfMobileApp2
    },

    drmaxNsfFacebook() {
      return this.config.drmaxNsfFacebook
    },

    drmaxNsfInstagram() {
      return this.config.drmaxNsfInstagram
    },

    drmaxNsfYoutube() {
      return this.config.drmaxNsfYoutube
    },

    drmaxNsfVerify() {
      return this.config.drmaxNsfVerify
    },

    drmaxNsfHeaderImage() {
      return this.config.drmaxNsfHeaderImage
    },

    drmaxNsfHeaderImageMobile() {
      return this.config.drmaxNsfHeaderImageMobile
    },

    drmaxNsfImageBeforeFooter() {
      return this.config.drmaxNsfImageBeforeFooter
    },

    drmaxNsfImageBeforeFooterMobile() {
      return this.config.drmaxNsfImageBeforeFooterMobile
    },

    drmaxNsfContentBabyCalendarWidget() {
      if (this.config?.drmaxNsfContentBabyCalendarWidget) {
        try {
          return JSON.parse(this.config.drmaxNsfContentBabyCalendarWidget)
        } catch (e) {
          logger.error('Baby Calendar JSON not valid: ', e.message)
        }
      }

      return {}
    },
  },
}
