import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import carrierCodes from './carrierCodes.mjs'
import config from '../index.mjs'
import features from './features.mjs'
import termsPages from './termsPages.mjs'
import paymentCodes from './paymentCodes.mjs'
import marketplace from './marketplace.mjs'
import inputTypes from './inputTypes.mjs'

export default defineAppConfig({
  ...carrierCodes,
  ...features,
  ...termsPages,
  ...paymentCodes,
  ...marketplace,
  ...inputTypes,
  boxShortDescription: ({ pickupPlaceAddress }) => pickupPlaceAddress?.city,
  defaultAgreements: (agreementsTopic) => [
    {
      channel: 'HEUREKA',
      topic: 'HEUREKA',
      value: 'N',
    },
    {
      channel: 'SMS',
      topic: agreementsTopic,
      value: 'N',
    },
  ],
  expressDeliveryExceptionDays: ['2022-12-10', '2022-12-17'],
  firstPartySellerName: 'Dr. Max',
  paymentFailedMessageComponent: 'general',
  shippingInfoBlocks: 'drmaxskshippingdrivein:drive-in-info',
  stockStatusChunkSize: 50,
  thankYouText: {
    payment: 'SMS',
    dispatch: 'SMS',
  },
  ignorePickupPlacesFromMagento: ['drmaxclickandcollectshipping'], // list of carrier codes for with we ignore magento's drmaxPickupIds
  limitDtcDeliveryTimesForMethods: [
    'drmaxpickupplace~drmax_box',
    'drmaxpickupplace~pharmacy',
    'drmaxclickandcollectshipping~drmaxclickandcollectshipping',
  ],
  ...config,
})
