import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import Tab from '@nsf/base/components/Tabs/Tab.vue';
import Tabs from '@nsf/base/components/Tabs/index.vue';
import { getComponent, convertSettingsToInlineStyles } from '@nsf/utils/PagebuilderUtils.js';
import { createElementId, createStyleElementContent } from '@nsf/base/utils/PagebuilderStyleUtils.js';
export default {
  name: 'PagebuilderTabs',
  functional: true,
  props: {
    item: {
      type: Object,
      default: function _default() {}
    }
  },
  render: function render(h, ctx) {
    var _ctx$props$item, _ctx$props$item2, _ctx$props$item3, _ctx$props$item3$sett, _ctx$props$item4, _ctx$props$item4$sett;
    var items = [];
    var children = ((_ctx$props$item = ctx.props.item) === null || _ctx$props$item === void 0 ? void 0 : _ctx$props$item.children) || [];
    var tabsSettings = (_ctx$props$item2 = ctx.props.item) === null || _ctx$props$item2 === void 0 ? void 0 : _ctx$props$item2.settings;
    if (!tabsSettings.visible) {
      return [];
    }
    var _iterator = _createForOfIteratorHelper(children),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _child$settings, _child$settings2;
        var child = _step.value;
        if (!(child !== null && child !== void 0 && (_child$settings = child.settings) !== null && _child$settings !== void 0 && _child$settings.visible)) {
          continue;
        }
        var tabContent = [];
        var hasProductsComponent = false;
        var _iterator2 = _createForOfIteratorHelper(child.children),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var _grandChild$settings;
            var grandChild = _step2.value;
            if (!(grandChild !== null && grandChild !== void 0 && (_grandChild$settings = grandChild.settings) !== null && _grandChild$settings !== void 0 && _grandChild$settings.visible)) {
              continue;
            }
            tabContent.push(h(getComponent(grandChild.type), {
              props: {
                item: grandChild
              }
            }));
            if (grandChild.type === 'Products') {
              hasProductsComponent = true;
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
        var desktopStyles = convertSettingsToInlineStyles(child.settings || {});
        var mobileStyles = convertSettingsToInlineStyles(child.mobileSettings || {});
        var elementId = createElementId(child === null || child === void 0 ? void 0 : child.id);
        var styleElementContent = createStyleElementContent(elementId, desktopStyles, mobileStyles);
        var styleElement = h('style', {}, styleElementContent);
        var tabWrapper = h('div', {
          attrs: {
            id: elementId
          }
        }, tabContent);
        items.push(h(Tab, {
          props: {
            item: child,
            title: child === null || child === void 0 ? void 0 : (_child$settings2 = child.settings) === null || _child$settings2 === void 0 ? void 0 : _child$settings2.name,
            hasMinHeight: hasProductsComponent
          }
        }, [tabWrapper, styleElement]));
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    var classes = ['pb-tabs'];
    if ((_ctx$props$item3 = ctx.props.item) !== null && _ctx$props$item3 !== void 0 && (_ctx$props$item3$sett = _ctx$props$item3.settings) !== null && _ctx$props$item3$sett !== void 0 && _ctx$props$item3$sett.cssClass) {
      classes.push(ctx.props.item.settings.cssClass);
    }
    var tabsData = {
      class: classes.join(' '),
      props: {
        activeTabIndex: (_ctx$props$item4 = ctx.props.item) === null || _ctx$props$item4 === void 0 ? void 0 : (_ctx$props$item4$sett = _ctx$props$item4.settings) === null || _ctx$props$item4$sett === void 0 ? void 0 : _ctx$props$item4$sett.defaultActiveTabIndex
      }
    };
    return items.length ? h(Tabs, tabsData, items) : '';
  }
};