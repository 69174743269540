export default {
  name: 'VRenderContent',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  render: function render(h) {
    return h('div', {}, this.items);
  }
};