<template>
  <li
    :class="['navigation-tab','h6',{ 'active' : isActive}]"
    :style="{'background-color': isActive && bgndColor}"
    data-test-id="carousel-nav-tab"
  >
    <div>
      <span v-if="name">{{ name }}</span>
      <span v-else class="navigation-dot" />
    </div>
  </li>
</template>

<script>
const DEFAULT_BACKGROUND_COLOR = '#ffffff'

export default {
  name: 'SliderNavigationTab',
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    slide: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name() {
      return this.slide?.settings?.name
    },
  },
  methods: {
    bgndColor() {
      return this.slide?.settings?.backgroundColor || DEFAULT_BACKGROUND_COLOR
    },
  },
}
</script>
<style scoped>
.li.active {
  cursor: pointer;
}
.navigation-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  background-color: rgba(0, 0, 0, 0);
}
</style>
