<template functional>
  <div
    v-if="$options.isVisible(props.item)"
    :class="$options.cssClass(props.item)"
  >
    <div
      :id="$options.elementId(props.item)"
      v-html="props.item.content"
    />
    <Component :is="'style'">
      {{ $options.styles(props.item) }}
    </Component>
  </div>
</template>

<script>

import { convertSettingsToInlineStyles } from '@nsf/utils/PagebuilderUtils.js'
import { createElementId, createStyleElementContent } from '@nsf/base/utils/PagebuilderStyleUtils.js'

export default {
  name: 'PagebuilderText',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  isVisible(item) {
    return item?.settings?.visible
  },

  elementId(item) {
    return createElementId(item?.id)
  },

  styles(item) {
    const desktopStyles = convertSettingsToInlineStyles(item?.settings || {})
    const mobileStyles = convertSettingsToInlineStyles(item?.mobileSettings || {})
    return createStyleElementContent(createElementId(item.id), desktopStyles, mobileStyles)
  },

  cssClass(item) {
    const classes = ['text pb-text']

    if (item?.settings?.cssClass) {
      classes.push(item.settings.cssClass)
    }

    return classes.join(' ')
  },
}
</script>
